import * as React from "react";
import formJson from "../../json/form1.json";
import { Looper } from "../../controller/looper";

export const step6 = (formJson, formRef) => {
  return (
    <div>
      <p>Fields marked with * are mandatory</p>
      <h2 className="k-text-primary mb-4">{formJson[1].StepLabels.Step6}</h2>
      <Looper data={formJson[0].Step6} whichStep={"Step6"} formRef={formRef} />
    </div>
  );
};
