import { createContext } from "react";
import { step6 } from "../screens/steps/step6";

export const FormContext = createContext();

export const onChangePersonRegion = (e1, required, setRequired, formData, completeString = "", setFormData) => {
	const value1 = e1.target.value?.trim();
	const targetName = e1?.target?.props?.name;
	var index = targetName?.slice(-1);

	if (targetName?.includes("DetailsOfUserLocationSuburbFreeText")) {
	} else if (targetName?.includes("DetailsOfUserLocation")) {
		if (value1 === completeString) setRequired((old) => old.filter((item) => item !== index));
		else setRequired((old) => [...new Set([...old, index])]);
	}
};

export const onChangeNumbersOfUserKnown = (setPersonRegionRequired, getData = "", targetValue = "") => {
	if (!!!targetValue) {
		const baseSelect = "DetailsOfUserLocation";
		const requiredIndex = [];
		for (let index = 1; index <= getData?.NumberOfPersonsUsing || 0; index++) {
			const element = !!getData[baseSelect + index];
			if (!element) requiredIndex.push(index.toString());
		}
		setPersonRegionRequired((old) => [...new Set([...old, ...requiredIndex])]);
	} else {
		const baseSelect = "DetailsOfUserLocation";
		const requiredIndex = [];
		for (let index = 1; index <= +targetValue; index++) {
			const element = !!getData[baseSelect + index];
			if (!element) requiredIndex.push(index.toString());
		}
		setPersonRegionRequired((old) => [...new Set([...old, ...requiredIndex])]);
	}
};
