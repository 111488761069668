import React from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import "../assets/stylesheets/sass/footer.scss";
function Footer() {
	return (
		<footer className="App-footer mt-4">
			<PanelBar
				className="w-full k-bg-primary align-items-center border-0"
				onSelect={(event) => {
					if (event.target.props.route) window.open(event.target.props.route, "_blank").focus();
				}}
			>
				<PanelBarItem title={"Help/assistance"} className="k-bg-primary main-panel" iconClass="fa-regular fa-triangle-exclamation" selected>
					<PanelBarItem title={"Goldfields"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/goldfields/" className="k-bg-primary" />
					<PanelBarItem title={"Great Southern"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/great-southern/" className="k-bg-primary" />
					<PanelBarItem title={"Kimberley"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/kimberley/" className="k-bg-primary" />
					<PanelBarItem title={"Perth metro"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/metro/" className="k-bg-primary" />
					<PanelBarItem title={"Midwest"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/midwest/" className="k-bg-primary" />
					<PanelBarItem
						title={"Ngaanyatjarra Lands"}
						route="https://vsu.mhc.wa.gov.au/vsu-in-wa/ngaanyatjarra-lands/"
						className="k-bg-primary"
					/>
					<PanelBarItem title={"Pilbara"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/pilbara/" className="k-bg-primary" />
					<PanelBarItem title={"Southwest"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/southwest/" className="k-bg-primary" />
					<PanelBarItem title={"Wheatbelt"} route="https://vsu.mhc.wa.gov.au/vsu-in-wa/wheatbelt/" className="k-bg-primary" />
				</PanelBarItem>
			</PanelBar>
			<div className="container text-center p-4">
				<ul className="my-2 list-inline justify-content-center">
					<li className="list-inline-item">
						<a href="https://vsu.mhc.wa.gov.au/" target={"_blank"} rel="noreferrer">
							VSU website
						</a>
					</li>
					<li className="list-inline-item">
						<a href="https://vsu.mhc.wa.gov.au/contactfeedback/" target={"_blank"} rel="noreferrer">
							Contact/Feedback
						</a>
					</li>
					<li className="list-inline-item">
						<a href="https://vsu.mhc.wa.gov.au/privacy/" target={"_blank"} rel="noreferrer">
							Privacy
						</a>
					</li>
					<li className="list-inline-item">
						<a href="https://vsu.mhc.wa.gov.au/disclaimer/" target={"_blank"} rel="noreferrer">
							Disclaimer
						</a>
					</li>
					<li className="list-inline-item">
						<a href="https://vsu.mhc.wa.gov.au/copyright/" target={"_blank"} rel="noreferrer">
							Copyright
						</a>
					</li>
				</ul>
				<div className="my-2">All contents copyright Government of Western Australia. All right reserved.</div>
			</div>
		</footer>
	);
}
export default Footer;
