import formJson from "../../json/form1.json";
import { Looper } from "../../controller/looper";
export const step2 = (formJson) => {
  return (
    <div>
      <p>Fields marked with * are mandatory</p>
      <h2 className="k-text-primary mb-4">{formJson[1].StepLabels.Step2}</h2>
      <Looper data={formJson[0].Step2} whichStep={"Step2"} />
    </div>
  );
};
