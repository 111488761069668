import React, { useEffect } from "react";
import Icon3 from "../../assets/images/VSU-combined-products-icon.png";
import { Link } from "react-router-dom";
import Icon1 from "../../assets/images/people.svg";
import { get, set, del } from "idb-keyval";
import "../../index.css";
export const Step1 = (formJson) => {
	const onClickBrief = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const formID = urlParams.get("formID") || "";
		const isFullForm = formID.split("_")[0] === "full";
		let newFormID = formID;
		get(formID).then((res) => {
			if (!!res) {
				newFormID = formID.replace("full_", "breif_");
				set(newFormID, res);
				del(formID);
			}

			window.location.href = `/vsu-incident-report-breif/${newFormID}`;
			return;
		});
	};
	return (
		<div>
			{formJson !== undefined && formJson.length !== 0 && (
				<>
					<h2 className="k-text-primary mb-4">{formJson[1].StepLabels.Step1}</h2>
					<div className="hotbox-wrapper">
						{formJson[0].Step1.map((key, index) => {
							return (
								<div
									className="hotbox-item"
									style={{
										cursor: "pointer",
										justifyContent: "space-between",
										display: "flex",
										flex: "1",
									}}
									key={key.Name}
								>
									
									{index === 0 ? (
										<button className="hotbox border-0" style={{ padding: "10px 12px" }}>
											<div style={{ height: "124px", display: "flex", alignItems: "center", flex: "1" }}>
												<img alt="extended-form" src={Icon1} style={{ height: "124px" }} className="icon2-new" />
											</div>

											<span>{key.Label}</span>
										</button>
									) : (
										// <Link onClick={onClickBrief} className="hotbox" to={`/vsu-incident-report-breif/${formID}`} style={{ padding: "10px 12px" }}>
										<Link onClick={onClickBrief} className="hotbox" style={{ padding: "10px 12px" }}>
											<div style={{ height: "124px", display: "flex", alignItems: "center", flex: "1" }}>
												<img alt="brief-form" src={Icon3} style={{ height: "124px" }} className="icon2-new" />
											</div>

											<span>{key.Label}</span>
										</Link>
									)}
								</div>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
};
