import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import {
	FormInput,
	FormComboBox,
	FormDropDownList,
	FormDatePicker,
	FormCheckbox,
	FormRadioGroup,
	FormMultiSelect,
	FormTextArea,
	FormMap,
	FormUpload,
	FormAutoComplete,
} from "./formcomponent";
import {
	emailValidator,
	inputDateValidator,
	fileValidator,
	lengthValidator,
	inputValidator,
	phoneValidator,
	textareaValidator,
	multiSelectValidator,
	selectValidator,
	extraFileValidator,
} from "./validator";
import moment from "moment";
import { Field } from "@progress/kendo-react-form";
import { Icon } from "@progress/kendo-react-common";
import { get, keys } from "idb-keyval";
import { filterBy } from "@progress/kendo-data-query";
import { FormContext, onChangeNumbersOfUserKnown, onChangePersonRegion, onChangeWitness } from "../context/FormContext";
let repeterValue = false;
export const Looper = (data) => {
	const { tncCheckBox, personRegionState, reporterRegionState } = useContext(FormContext);
	const [isTnCChecked, setIsTncChecked] = tncCheckBox;
	const [personRegionRequired, setPersonRegionRequired] = personRegionState;
	const [isLocationManualRequired, setIsLocationManualRequired] = reporterRegionState;

	const timeout = useRef();
	const [Adata, setAdata] = useState([]);
	const params = useParams();
	const [CCAData, setCCAData] = useState(false);
	const [CCADataReg, setCCADataReg] = useState(false);
	const [formData, setFormData] = useState({});
	const [formValue] = useState({});

	// let agenciesArray = Array.from({ length: agenciesLength }, (_, index) => index + 1);

	const [repeterLoopValue, setrepeterLoopValue] = useState({
		DetailsOfUser: [0],
		AgencyDetails: [1],
		WitnessDetails: [1],
	});
	const [isOnline, setIsOnline] = useState();
	useEffect(() => {
		setIsOnline(navigator.onLine);
		getCCAName(formData);
		getCCANamebyRegion(formData);
	}, [navigator.onLine]);
	useEffect(() => {
		let idbDataKey = params.id;
		const fetchData = async () => {
			const getData = await get(idbDataKey);
			if (getData) {
				if (!!getData?.LocationSuburb) setIsLocationManualRequired(false);
				onChangeNumbersOfUserKnown(setPersonRegionRequired, getData, "");
				setFormData(getData);
			}
		};
		if (idbDataKey !== undefined) {
			fetchData();
		}
	}, [params]);
	useEffect(() => {
		getCCAName(formData);
		getCCANamebyRegion(formData);
	}, [formData]);
	useEffect(() => {
		setrepeterLoopValue((repeterLoopValue) => {
			const witness = Object.entries(data.formRef?.current?.props?.initialValues || {}).filter(
				([key, value]) => key.includes("WitnessFullName") && !!value
			);
			const witnessArray = witness.map((x) => parseInt(x[0].split(/[^0-9]/).filter(Boolean)[0]));

			const agencies = Object.entries(data.formRef?.current?.props?.initialValues || {}).filter(
				([key, value]) => key.includes("AgencyContactedFullName") && !!value
			);

			const agenciesArray = agencies.map((x) => parseInt(x[0].split(/[^0-9]/).filter(Boolean)[0]));

			return {
				...repeterLoopValue,
				WitnessDetails: witnessArray.length > 0 ? witnessArray : [1],
				AgencyDetails: agenciesArray.length > 0 ? agenciesArray : [1],
			};
		});

		return () => {};
	}, [data?.formRef, data?.whichStep]);

	const getCCAName = (values) => {
		if (isOnline && !!values?.LocationSuburb) {
			let val = values.LocationSuburb;
			fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/displayname/${val}`)
				.then((response) => {
					if (response.StatusCode !== 400) return response.json();
					else throw { code: response.StatusCod, message: "Error" };
				})
				.then((result) => setCCAData(result))
				.catch((error) => setCCAData(false));
		} else {
			setCCAData(false);
		}
	};
	const getCCANamebyRegion = (values) => {
		if (isOnline && !!values?.LocationSuburbRegion && (values.LocationSuburb === undefined || values.LocationSuburb === "")) {
			let val = values.LocationSuburbRegion.ItemDisplayName;
			//console.log(val);
			fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/healthregion/${val}`)
				.then((response) => {
					if (response.StatusCode !== 400) {
						return response.json();
					} else {
						const errorMessage = { code: response.StatusCod, message: "Error" };
						throw errorMessage;
					}
				})
				.then((result) => {
					setCCADataReg(result);
				})
				.catch((error) => {
					setCCADataReg(false);
				});
		} else {
			setCCADataReg(false);
		}
	};
	const findCommonElements2 = (arr1, arr2) => {
		// Create an empty object
		let obj = {};

		// Loop through the first array
		for (let i = 0; i < arr1.length; i++) {
			// Check if element from first array already exist in object or not
			if (!obj[arr1[i]]) {
				// If it doesn't exist assign the properties equals to the elements in the array
				const element = arr1[i];
				obj[element] = true;
			}
		}

		// Loop through the second array
		for (let j = 0; j < arr2.length; j++) {
			// Check elements from second array exist in the created object or not
			if (obj[arr2[j]]) {
				return true;
			}
		}
		return false;
	};
	const generateField = (key, index, parentIndex) => {
		let showField = true;

		if (key.Dependency) {
			showField =
				!!formData[key?.Dependency?.Name] &&
				((!!key.Dependency?.Value && key.Dependency.Value.includes(formData[key.Dependency.Name])) || !key.Dependency?.Value);

			// Dependency has many values

			if (key.Name === "LocationSuburbManual" && key.Dependency !== undefined) {
				let DependentField = key.Dependency.Name;
				showField = true;
				if (DependentField !== undefined && DependentField !== null) {
					let formValueDF = formData[DependentField];
					if (formValueDF === undefined || formValueDF === null || formValueDF === "Others") {
						key.Required = true;
					} else {
						key.Required = false;
					}
				}
				if (!!formData[DependentField]) showField = false;
			}

			if (formData[key.Dependency.Name] && key.Dependency.Value && Array.isArray(key.Dependency.Value.split("|"))) {
				if (!Array.isArray(formData[key.Dependency.Name])) {
					showField = findCommonElements2(key.Dependency.Value.split("|"), [formData[key.Dependency.Name]]);
				} else {
					showField = findCommonElements2(key.Dependency.Value.split("|"), formData[key.Dependency.Name]);
				}
			}
			if (key.Name === "WitnessOther" || key.Name === "OtherAgencyContacted") {
				showField =
					!!data?.formRef?.current?.valueGetter(key.Dependency.Name + parentIndex) &&
					!!key.Dependency.Value &&
					data?.formRef?.current?.valueGetter(key.Dependency.Name + parentIndex) === "Other (please describe)";
			}
			if (key.Name === "DetailsOfUserLocationSuburbFreeText" || key.Name === "DetailsOfUserLocationSuburbRegion") {
				showField = true;
			}
			if (key.Name === "DetailsOfUserLocationSuburbFreeText" && !!formData[`DetailsOfUserLocation${parentIndex}`]) {
				showField = false;
			}

			if (key.Name === "DetailsOfUserLocationSuburbRegion") {
				if (!!formData[`DetailsOfUserLocation${parentIndex}`]?.trim()) {
					showField = false;
				} else showField = true;
			}

			if (key.Name === "DetailsOfUserOtherLocationSuburbRegion") {
				// if (!!formData[`DetailsOfUserLocationSuburbRegion${parentIndex}`]?.trim()) {
				if (formData[`${key.Dependency.Name}${parentIndex}`]?.ItemCodeName === "Other") {
					showField = true;
				} else showField = false;
			}
		}
		const isPersonKnownField = key.Name.includes("DetailsOfUserLocationSuburbRegion");
		if (!showField) {
			formData[key.Name + (parentIndex || "")] = "";
		}
		switch (key.Type) {
			case "text":
				const isLocationManualSubfield = key.Name === "LocationSuburbManual" && !!key.Dependency;
				const isDetailUserRequired = key.Name === "DetailsOfUserLocationSuburbFreeText" ? false : key.Required;
				if (key.Name === "OtherSubstancesUsed") {
					const isSubstancesUsedArray = Array.isArray(formData?.SubstancesUsed);
					showField = isSubstancesUsedArray && formData?.SubstancesUsed?.filter((x) => x?.includes("Other"))?.length > 0;
				}
				return (
					(isLocationManualSubfield ? isLocationManualRequired : showField) && (
						<>
							<Field
								// key={index + "_" + (parentIndex || "")}
								key={key.Name + (parentIndex || "")}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								placeholder={key.PlaceholderText}
								hint={key.TooltipText}
								required={isLocationManualSubfield ? isLocationManualRequired : isDetailUserRequired}
								component={FormInput}
								onChange={(e) => {
									const textValue = e.target.value?.trim();
									if (isLocationManualRequired)
										setFormData((oldData) => ({ ...oldData, LocationSuburb: "", [key.Name + (parentIndex || "")]: textValue }));
									else setFormData((oldData) => ({ ...oldData, [key.Name + (parentIndex || "")]: textValue }));
								}}
								validator={(e) => {
									if (key.Name === "FullName") return inputValidator(e, key.ValidationMessage) || lengthValidator(e);
									return (isLocationManualSubfield ? isLocationManualRequired : isDetailUserRequired)
										? inputValidator(e, key.ValidationMessage)
										: false;
								}}
							/>
						</>
					)
				);
				break;

			case "email":
				return (
					showField && (
						<>
							<Field
								// key={index + "_" + (parentIndex || "")}
								key={key.Name + (parentIndex || "")}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								placeholder={key.PlaceholderText}
								hint={key.TooltipText}
								required={key.Required}
								component={FormInput}
								type="email"
								onChange={(e) => setFormData({ ...formData, [key.Name + (parentIndex || "")]: e.target.value })}
								validator={(e) => (key.Required ? emailValidator(e, key.ValidationMessage) : false)}
							/>
						</>
					)
				);
			case "phone":
				return (
					showField && (
						<Field
							// key={index + "_" + (parentIndex || "")}
							key={key.Name + (parentIndex || "")}
							id={key.Name + (parentIndex || "")}
							name={key.Name + (parentIndex || "")}
							label={key.Label}
							placeholder={key.PlaceholderText}
							hint={key.TooltipText}
							required={key.Required}
							component={FormInput}
							onChange={(e) =>
								setFormData({
									...formData,
									[key.Name + (parentIndex || "")]: e.target.value,
								})
							}
							validator={(e) => (key.Required ? phoneValidator(e, key.ValidationMessage) : false)}
						/>
					)
				);

			case "textarea":
				const isDescriptionofPersonKnown = key.Name === "DescriptionOfPersonsKnown";
				if (isDescriptionofPersonKnown && ["No", "Some"].includes(data.formRef?.current?.valueGetter("PersonsKnown"))) showField = true;
				return (
					showField && (
						<div>
							{data.formRef?.current?.valueGetter("PersonsKnown") === "Some" && isDescriptionofPersonKnown && (
								<h2 className="k-text-primary mt-4" style={{ marginBottom: -8 }}>
									Details of unknown person/s
								</h2>
							)}
							<Field
								key={key.Name + (parentIndex || "")}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								placeholder={key.PlaceholderText}
								hint={key.TooltipText}
								required={key.Required}
								max={key.MaxLength}
								component={FormTextArea}
								onChange={(e) =>
									setFormData({
										...formData,
										[key.Name + (parentIndex || "")]: e.target.value?.trim(),
									})
								}
								validator={(e) => (key.Required ? textareaValidator(e, key.MaxLength, key.ValidationMessage) : false)}
							/>
						</div>
					)
				);

			case "date":
				return (
					showField && (
						<Field
							// key={index + "_" + (parentIndex || "")}
							key={key.Name + (parentIndex || "")}
							id={key.Name + (parentIndex || "")}
							name={key.Name + (parentIndex || "")}
							label={key.Label}
							placeholder={key.PlaceholderText}
							hint={key.TooltipText}
							required={key.Required}
							component={FormDatePicker}
							format="dd/MM/yyyy"
							onChange={(e) => {
								setFormData({
									...formData,
									[key.Name + (parentIndex || "")]: e.value,
								});
							}}
							validator={(e) => (key.Required ? inputDateValidator(e, key.ValidationMessage) : false)}
						/>
					)
				);

			case "select":
				if (key.Name === "LocationSuburbRegion") {
					if (!!data.formRef?.current?.valueGetter("LocationSuburbManual")) {
						showField = true;
					} else {
						showField = false;
					}
				}
				const isLocationSuburbRegion = key.Name === "LocationSuburbRegion" || key.Name === "DetailsOfUserLocationSuburbRegion";
				const labelValueObject = isLocationSuburbRegion ? { textField: "ItemDisplayName", dataItemKey: "ItemCodeName" } : {};
				const selectOptions =
					key.Name === "DetailsOfUserLocationSuburbRegion"
						? [...key.Options, { ItemDisplayName: "Other", ItemCodeName: "Other" }]
						: key.Options;
				if (key.Name === "NumberOfPersonsUsing" && ["Yes", "Some"].includes(data.formRef?.current?.valueGetter("PersonsKnown")))
					showField = true;

				return (
					showField && (
						<>
							<Field
								key={index + "_" + (parentIndex || "") + key.Name}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								placeholder={key.PlaceholderText}
								hint={key.TooltipText}
								required={key.Required}
								value={key.PlaceholderText}
								data={selectOptions}
								defaultItem={
									isLocationSuburbRegion
										? { ItemDisplayName: key.PlaceholderText, ItemCodeName: key.PlaceholderText }
										: key.PlaceholderText
								}
								component={FormDropDownList}
								{...labelValueObject}
								onChange={(e) => {
									if (key.Name === "NumberOfPersonsUsing")
										onChangeNumbersOfUserKnown(setPersonRegionRequired, formData, e.target.value);
									if (key.Name === "LocationSuburbRegion") {
										setFormData((formData) => ({ ...formData, LocationSuburb: "" }));
										data.formRef.current?.onChange("LocationSuburb", "");
									}
									if (key.Name === "DetailsOfUserLocationSuburbRegion") {
										setFormData((formData) => ({ ...formData, ["DetailsOfUserLocation" + parentIndex]: "" }));
										data.formRef.current?.onChange("DetailsOfUserLocation" + parentIndex, "");
									}

									setFormData({
										...formData,
										[key.Name + (parentIndex || "")]: e.target.value,
									});
								}}
								validator={(e) => (key.Required ? selectValidator(e, key.ValidationMessage, key.PlaceholderText, key.Name) : false)}
							/>
						</>
					)
				);

			case "multiselect":
				const clearValues = () => {
					data?.formRef?.current?.onChange(key.Name + (parentIndex || ""), { value: [] });
					setFormData({
						...formData,
						[key.Name + (parentIndex || "")]: [],
					});
				};
				return (
					showField && (
						<>
							<Field
								key={index + "_" + key.Name + (parentIndex || "")}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								placeholder={key.PlaceholderText}
								hint={key.TooltipText}
								required={key.Required}
								data={key.Options}
								component={FormMultiSelect}
								clearValues={clearValues}
								onChange={(e) => {
									setFormData({
										...formData,
										[key.Name + (parentIndex || "")]: e?.value || e,
									});
								}}
								validator={(e) => (key.Required ? multiSelectValidator(e, key.ValidationMessage) : false)}
							/>
						</>
					)
				);
			case "combobox":
				return (
					<>
						{showField && (
							<Field
								// id={key.Name + (parentIndex || "")}
								// key={index + "_" + (parentIndex || "")}
								key={key.Name + (parentIndex || "")}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								placeholder={key.PlaceholderText}
								hint={key.TooltipText}
								// required={key.Required}
								required={isPersonKnownField ? personRegionRequired.includes(parentIndex.toString()) : key.Required}
								data={key.Options}
								component={FormComboBox}
								allowCustom={true}
								defaultValue={formValue[key.Name]}
								onChange={(e) =>
									setFormData({
										...formData,
										[key.Name + (parentIndex || "")]: e.target.value,
									})
								}
								validator={(e) =>
									(isPersonKnownField ? personRegionRequired.includes(parentIndex.toString()) : key.Required)
										? inputValidator(e, key.ValidationMessage)
										: false
								}
							/>
						)}{" "}
					</>
				);

			case "label":
				let renderHTML = key.HTMLValue;
				let HTMLInfoValue = key.HTMLInfoValue;
				const filterData = (value) => {
					const asdf = key.Options;
					const filter = {
						logic: "or",
						filters: [
							{
								field: "ItemDisplayName",
								operator: "startswith",
								value: value,
								ignoreCase: true,
							},
						],
					};
					let afilterby = filterBy(asdf, filter);
					return afilterby;
				};
				const dataUsed = CCAData?.CADS ? CCAData : CCADataReg;
				const areBothInvalid = !dataUsed?.CADS;
				showField = true;
				if (showField && !areBothInvalid) {
					renderHTML = `${renderHTML}<strong> ${dataUsed.CADS}</strong>. `;
					HTMLInfoValue = `${HTMLInfoValue} via <a href="tel:${dataUsed.Phone}">${dataUsed.Phone}</a> or <a href="mailto:${dataUsed.Email}">${dataUsed.Email}</a>.`;
				} else {
					showField = false;
				}
				return (
					showField && (
						<div key={key.Name + (parentIndex || "")}>
							<div className="mt-4" dangerouslySetInnerHTML={{ __html: renderHTML }}></div>
							<div dangerouslySetInnerHTML={{ __html: HTMLInfoValue }}></div>
						</div>
					)
				);
			case "labelregion":
				let renderHTMLReg = key.HTMLValue;
				let HTMLInfoValueReg = key.HTMLInfoValue;
				const filterDataReg = (value) => {
					const asdf = key.Options;
					const filter = {
						logic: "or",
						filters: [
							{
								field: "ItemDisplayName",
								operator: "startswith",
								value: value,
								ignoreCase: true,
							},
						],
					};
					let afilterby = filterBy(asdf, filter);
					return afilterby;
				};
				// //console.log(key);
				if (showField) {
					if (!!CCADataReg) {
						renderHTMLReg = `${renderHTMLReg}<strong> ${CCADataReg.CADS}</strong>. `;
						HTMLInfoValueReg = `${HTMLInfoValueReg} via <a href="tel:${CCADataReg.Phone}">${CCADataReg.Phone}</a> or <a href="mailto:${CCADataReg.Email}">${CCADataReg.Email}</a>.`;
					} else {
						showField = false;
					}
				}
				return (
					showField && (
						<div key={key.Name + (parentIndex || "")}>
							<div className="mt-4" dangerouslySetInnerHTML={{ __html: renderHTMLReg }}></div>
							<div dangerouslySetInnerHTML={{ __html: HTMLInfoValueReg }}></div>
						</div>
					)
				);
			case "autocomplete":
				let options = key.Options;

				const filterData2 = (value) => {
					const data = key.Options;
					const filter = {
						logic: "or",
						filters: [
							{
								field: "ItemDisplayName",
								operator: "startswith",
								value: value,
								ignoreCase: true,
							},
							{
								field: "ExtraInfo",
								operator: "startswith",
								value: value,
								ignoreCase: true,
							},
						],
					};
					let afilterby = filterBy(data, filter);
					return afilterby;
				};

				const onChange = (event) => {
					const value = event.target.value;
					const targetName = event?.target?.props?.name;
					if (targetName === "LocationSuburb") options = filterData2(value);
					setAdata(options);
					timeout.current = setTimeout(() => {
						setAdata(filterData2(value));
					}, 500);
					const stateData =
						value.length < 3
							? {
									data: key.Options,
									opened: false,
							  }
							: {
									data: filterData2(value),
									opened: true,
							  };
					const eventType = event.nativeEvent.type;
					const nativeEvent = event.nativeEvent;
					const valueSelected = eventType === "click" || (eventType === "keydown" && nativeEvent.keyCode === 13);
					if (targetName?.includes("DetailsOfUserLocation")) {
						onChangePersonRegion(event, personRegionRequired, setPersonRegionRequired, formData, stateData.data[0]?.ItemDisplayName);
						data?.formRef.current?.onChange("DetailsOfUserLocationSuburbFreeText" + parentIndex, "");
						data?.formRef.current?.onChange("DetailsOfUserLocationSuburbRegion" + parentIndex, "");
						data?.formRef.current?.onChange("DetailsOfUserOtherLocationSuburbRegion" + parentIndex, "");
					}
					if (valueSelected && stateData.data[0]?.ItemDisplayName === value) {
						stateData.opened = false;
						setIsLocationManualRequired(false);
						setFormData((oldData) => ({ ...oldData, [key.Name + (parentIndex || "")]: stateData.data[0].ItemDisplayName }));
						if (key.Name === "LocationSuburb") {
							setFormData((oldData) => ({ ...oldData, LocationSuburbManual: "", LocationSuburbRegion: "" }));
							data.formRef.current?.onChange("LocationSuburbManual", "");
							data.formRef.current?.onChange("LocationSuburbRegion", "");
						}
					} else {
						setIsLocationManualRequired(true);
						setFormData((oldData) => ({ ...oldData, [key.Name + (parentIndex || "")]: "" }));
					}
				};
				return (
					showField && (
						<>
							<Field
								// key={index + "_" + (parentIndex || "")}
								key={key.Name + "_" + (parentIndex || "")}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								placeholder={key.PlaceholderText}
								hint={key.TooltipText}
								required={key.Required}
								data={Adata}
								component={FormAutoComplete}
								allowCustom={true}
								onChange={onChange}
								textField="ItemDisplayName"
								validator={(e) => (key.Required ? inputValidator(e, key.ValidationMessage) : false)}
							/>
						</>
					)
				);
			case "checkbox":
				const isTncCheckbox = key.Name === "termsandconditions";
				return (
					showField && (
						<Field
							// key={index + "_" + (parentIndex || "")}
							key={key.Name + (parentIndex || "")}
							id={key.Name + (parentIndex || "")}
							name={key.Name + (parentIndex || "")}
							label={key.Label}
							hint={key.TooltipText}
							required={key.Required}
							data={key.Options}
							component={FormCheckbox}
							onChange={(e) => {
								setFormData({
									...formData,
									[key.Name + (parentIndex || "")]: e.target.value,
								});

								if (key.Name === "termsandconditions") setIsTncChecked(e.target.value);
							}}
							// validator={(e) =>
							//   key.Required ? inputValidator(e, key.ValidationMessage) : false
							// }
							validator={(e) =>
								!isTncCheckbox
									? key.Required
										? inputValidator(e, key.ValidationMessage)
										: false
									: isTnCChecked
									? false
									: key.ValidationMessage.Validation
							}
						/>
					)
				);
			case "radio":
				return (
					showField && (
						<Field
							// key={index + "_" + (parentIndex || "")}
							key={key.Name + (parentIndex || "")}
							id={key.Name + (parentIndex || "")}
							name={key.Name + (parentIndex || "")}
							label={key.Label}
							hint={key.TooltipText}
							required={key.Required}
							data={key.Options.map((element) => {
								return { label: element, value: element, key: element };
							})}
							component={FormRadioGroup}
							onChange={(e) => setFormData({ ...formData, [key.Name + (parentIndex || "")]: e.value })}
							validator={(e) => (key.Required ? inputValidator(e, key.ValidationMessage) : false)}
						/>
					)
				);
			case "file":
				return (
					showField && (
						<>
							<Field
								key={key.Name + (parentIndex || "")}
								id={key.Name + (parentIndex || "")}
								name={key.Name + (parentIndex || "")}
								label={key.Label}
								hint={key.TooltipText}
								required={key.Required}
								restrictions={key.Restrictions}
								component={FormUpload}
								disabled={!isOnline}
								formData={formData}
								setFormData={setFormData}
								validator={(e) => extraFileValidator(e, 5, key.Restrictions.allowedExtensions)}
							/>
						</>
					)
				);
			case "map":
				const isBrief = window.location.href.includes("vsu-incident-report-breif");
				const fName = data.formRef.current?.valueGetter("FullName");
				const formFullName = isBrief
					? "breif_" + fName.trim() + "_" + moment().format("DDMMYYYY")
					: "full_" + fName.trim() + "_" + moment().format("DDMMYYYY");
				// showField = navigator.onLine;
				return (
					showField && (
						<Field
							// key={index + "_" + (parentIndex || "")}
							key={key.Name + (parentIndex || "")}
							id={key.Name + (parentIndex || "")}
							name={key.Name + (parentIndex || "")}
							label={key.Label}
							placeholder={key.PlaceholderText}
							hint={key.TooltipText}
							required={key.Required}
							component={FormMap}
							formFullName={formFullName}
							onChange={(val) => {
								data?.formRef.current?.onChange(key.Name, { value: val });
								setFormData({ ...formData, [key.Name + (parentIndex || "")]: val });
							}}
						/>
					)
				);
			case "repeater":
				let valueFrom = "Yes";
				let showAddMoreButton = false;
				if (key.Dependency !== undefined) {
					valueFrom = key.Dependency.Value;
				}
				let a = key.Repeater,
					loopValue = formData[key.ValueFrom] === valueFrom || formValue[key.ValueFrom] > 0 ? 1 : 0;

				if (key.Dependency !== undefined && key.Dependency !== null) {
					loopValue = formData[key.Dependency.Name] === valueFrom || formValue[key.ValueFrom] > 0 ? 1 : 0;
				}
				//TODO: Make this dynamic
				if (key.Name === "DetailsOfUser") {
					repeterLoopValue[key.Name] = Array.from({ length: data.formRef.current?.valueGetter("NumberOfPersonsUsing") }, (_, i) => i + 1);

					if (repeterLoopValue[key.Name].length > 0 && ["Yes", "Some"].includes(data.formRef.current?.valueGetter("PersonsKnown")))
						showField = true;
				} else if (key.Name === "WitnessDetails") {
					const isWitnessExists = data.formRef.current?.valueGetter("OtherInformer") === "Yes";
					showField = isWitnessExists;
					showAddMoreButton = isWitnessExists;
					// showField = true;
				} else if (key.Name === "AgencyDetails") {
					const isAgencyExists = data.formRef.current?.valueGetter("HasContactedAgency") === "Yes";
					showField = isAgencyExists;
					showAddMoreButton = isAgencyExists;

					// showField = true;
				}

				const handleAddMore = (i) => {
					let a = repeterLoopValue[key.Name];
					let arrayFromNumber = Array.from({ length: a.length + 1 }, (_, i) => i + 1);
					setrepeterLoopValue((repeterLoopValue) => ({ ...repeterLoopValue, [key.Name]: arrayFromNumber }));
				};
				const reducearray = (index) => {
					let a = repeterLoopValue[key.Name];
					let arrayFromNumber = a.filter((number) => number !== index);
					const needToDelete = ["WitnessAgency", "WitnessContactNumber", "WitnessFullName"];
					// needToDelete.forEach((x) => data?.formRef.current.onChange(x + index, { value: "" }));
					setrepeterLoopValue((repeterLoopValue) => ({ ...repeterLoopValue, [key.Name]: arrayFromNumber }));
				};
				return (
					<div key={key.Name + (parentIndex || "")}>
						{showField &&
							repeterLoopValue[key.Name].map((i) => {
								return (
									<div key={`${i} ${key.Name} repeater`}>
										<hr></hr>
										<h4 className="k-text-primary">
											<strong>
												{repeterLoopValue[key.Name]?.length > 1
													? key.Label.replace("#", i.toString())
													: key.Label.replace("#", "")}{" "}
											</strong>
											{key.Name !== "DetailsOfUser" && (
												<a
													className="k-button k-button-lg k-button-outline k-button-outline-primary k-rounded-md  ml-2"
													onClick={() => reducearray(i)}
												>
													<Icon name="close"></Icon>
												</a>
											)}
										</h4>
										{a.map((kItem, jIndex) => generateField(kItem, jIndex, i))}
									</div>
								);
							})}

						{showAddMoreButton && key.HasAddMoreButton && (
							<>
								<div
									className="k-bg-primary border-0 text-white d-flex justify-content-center align-items-center p-3 mt-4 mb-2 w-100"
									style={{ height: "50px", cursor: "pointer" }}
									onClick={() => {
										handleAddMore(index);
									}}
								>
									<Icon name="plus"></Icon>
									{key.AddMoreLabel}
								</div>
								<hr></hr>
							</>
						)}
					</div>
				);
			default:
				return (
					showField && (
						<Field
							// key={index + "_" + (parentIndex || "")}
							key={key.Name + (parentIndex || "")}
							id={key.Name + (parentIndex || "")}
							name={key.Name + (parentIndex || "")}
							label={key.Label}
							placeholder={key.PlaceholderText}
							hint={key.TooltipText}
							required={key.Required}
							component={FormInput}
							onChange={(e) =>
								setFormData({
									...formData,
									[key.Name + (parentIndex || "")]: e.target.value,
								})
							}
						/>
					)
				);
		}
	};
	const inputs = data.data;

	return <>{inputs.map((key, index) => generateField(key, index, repeterValue))}</>;
};
