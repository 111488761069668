import React, { useEffect, useState } from 'react';
import './pwa.css';
const PWAInstaller = () => {
    const [installPrompt, setInstallPrompt] = useState(null);
    const [manifestData, setManifestData] = useState(null);


    useEffect(() => {
        window.addEventListener('beforeinstallprompt', handleInstallPrompt);
        return () => {
            window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
        };
    }, []);
    useEffect(() => {
        // Fetch the manifest.json file
        fetch('/manifest.json')
            .then((response) => response.json())
            .then((data) => {
                setManifestData(data);
            })
            .catch((error) => {
                console.error('Error fetching manifest.json', error);
            });
    }, []);
    const handleInstallPrompt = (event) => {
        event.preventDefault();
        setInstallPrompt(event);
    };

    const handleInstallClick = (e) => {
        if (installPrompt) {
            installPrompt.prompt();
            installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('PWA installation accepted');
                } else {
                    console.log('PWA installation dismissed');
                }
                setInstallPrompt(null);
            });
        }
    };

    // Function to check if the user is using an Android device
    const isAndroid = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return /android/.test(userAgent);
    };


    const isPWAInstalled = () => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return true;
        }
        return false;
    };

    return (
        <>
            {!isPWAInstalled() && <div className={`pwa-installer-container ${isAndroid() ? 'show' : ''}`}>
                <div className="pwa-installer-content">
                    {/* Add your app icon, app name, and app URL here */}
                    {manifestData && (
                        <div>
                            <img src={manifestData.icons[0].src} alt="App Icon" className="app-icon" />
                            <p className="app-name">Install {manifestData.name}</p>

                        </div>
                    )}
                    <button onClick={handleInstallClick} className="k-button k-button-lg k-button-solid k-button-solid-primary k-rounded-md install-button">
                        Install
                    </button>
                </div>
            </div>}
        </>
    );
};

export default PWAInstaller;
