import React from "react";
import formJson from "../../json/form1.json";
import { Looper } from "../../controller/looper";

export const step3 = (formJson, form) => {
  return (
    <div>
      <p>Fields marked with * are mandatory</p>
      <h2 className="k-text-primary mb-4">{formJson[1].StepLabels.Step3}</h2>

      <Looper data={formJson[0].Step3} whichStep={"Step3"} formRef={form} />
    </div>
  );
};
