import React, { useEffect, createContext, useState, useRef } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { Step1 } from "./steps/step1";
import { step2 } from "./steps/step2";
import { step3 } from "./steps/step3";
import { step4 } from "./steps/step4";
import { step5 } from "./steps/step5";
import { step6 } from "./steps/step6";
import { step7 } from "./steps/step7";
import { Preview } from "../controller/preview";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Icon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { get, set, del, values, update, keys } from "idb-keyval";
import moment from "moment";
import { useParams, useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { Success } from "../controller/success";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormContext } from "../context/FormContext";
import { useSearchParams } from "react-router-dom";
import "../assets/stylesheets/css/form.css";
const VSUIncident = (props) => {
	const navigate = useNavigate();

	const [isTnCChecked, setIsTnCChecked] = useState(false);
	const [personRegionRequired, setPersonRegionRequired] = useState([]);
	const [CCAData, setCCAData] = React.useState(false);
	const [relatedCCA, setRelatedCCA] = useState([]);
	const [CCADataregion, setCCADataregion] = React.useState(false);
	const [isLocationManualRequired, setIsLocationManualRequired] = useState(true);
	const params = useParams();
	const [isOnline, setIsOnline] = React.useState();
	const [searchParams, setSearchParams] = useSearchParams();

	const MyForm = useRef(null);
	const [visibleDialog, setVisibleDialog] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [Loading, setLoading] = useState(true);
	const [stepPages, setStepPages] = useState(0);
	const [formJson, setformJson] = useState([]);
	const [step, setStep] = useState(0);
	const [showPreview, setShowPreview] = useState(false);
	const [formValue, setFormValue] = useState({});

	const [steps, setSteps] = useState([
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
	]);
	const toggleDialog = (key) => {
		setVisibleDialog(!visibleDialog);
	};
	useEffect(() => {
		setIsOnline(navigator.onLine);
	}, [navigator.onLine]);

	useEffect(() => {
		const fetchData = async () => {
			let retrievedData = await get("FormData");
			if (retrievedData !== undefined) {
				setformJson(retrievedData.formTemplate);
				let allSteps = [
					Step1(retrievedData.formTemplate),
					step2(retrievedData.formTemplate),
					step3(retrievedData.formTemplate, MyForm),
					step4(retrievedData.formTemplate),
					step5(retrievedData.formTemplate, MyForm),
					step6(retrievedData.formTemplate, MyForm),
					step7(retrievedData.formTemplate),
				];

				setStepPages(allSteps);
				if (!!params?.id) {
					const getData = await get(params.id);
					setFormValue(getData);
					setIsTnCChecked(getData?.termsandconditions || false);
					setStep(1);
				}
				setLoading(false);
			}
		};
		fetchData();
	}, [params]);

	const testIDBKeyval = async (formData, steps) => {
		// console.log(formData);
		if (!!formData) {
			if (!!formData?.FullName) {
				const fullName = formData.FullName.trim();
				let idbDataKey = "full_" + fullName + "_" + moment().format("DDMMYYYY");
				if (!!params.id) idbDataKey = params.id;

				const { LocationAddress, ...form } = formData;

				update(idbDataKey, (data) => {
					return { ...data, ...form };
				});
			}
		}
	};

	const lastStepIndex = steps.length - 1;
	const isLastStep = lastStepIndex === step;
	const isPreviousStepsValid = steps.slice(0, step).findIndex((currentStep) => currentStep.isValid === false) === -1;
	const onStepSubmit = (event) => {
		const { isValid, values } = event;
		let isPreview = event.event?.target.classList.contains("preview") || event.event?.target.parentElement.classList.contains("preview");
		setFormValue(values);
		if (isPreview) {
			testIDBKeyval(JSON.parse(JSON.stringify(values)), step);
			setTimeout(() => setShowPreview(true), 100);
		} else {
			if (step === 0) {
				const formID = params?.id || searchParams.get("formID") || "";
				const isFullForm = formID.split("_")[0] === "full";
				let newFormID = formID;
				if (!!formID) {
					get(formID).then((res) => {
						if (!!res) {
							if (!isFullForm) {
								newFormID = formID.replace("breif_", "full_");
								set(newFormID, res);
								del(formID);
							}
							navigate(`/vsu-incident-report/${newFormID}`);
							setFormValue(res);
							setIsTnCChecked(res?.termsandconditions || false);
						}
						return;
					});
				}
			}
			window.scrollTo(0, 0);
			const currentSteps = steps.map((currentStep, index) => ({
				...currentStep,
				key: index,
				isValid: index === step ? isValid : currentStep.isValid,
			}));
			setSteps(currentSteps);

			if (!isValid) return;

			testIDBKeyval(JSON.parse(JSON.stringify(values)), step);

			if (isValid) setStep(() => Math.min(step + 1, lastStepIndex));
			let emailtoSend = values.EmailAddress;
			if (!!values?.EmailCopyTo) {
				emailtoSend = values.EmailAddress;
			}
			if (isLastStep) {
				// setLoading(true);
				const myHeaders = new Headers({
					formName: "form1",
					email: emailtoSend,
					"Content-Type": "application/json",
					Cookie: "CMSPreferredUICulture=en-us",
				});
				// "DetailsOfUser"
				const formKeys = Object.keys(values);
				const personKnown = values?.PersonsKnown !== "No";
				const personUsing = values?.NumberOfPersonsUsing;

				const modifiedData = { ...values };

				const otherWitness = values?.OtherInformer !== "No";
				const otherAgency = values?.HasContactedAgency !== "No";

				const witnessFields = ["WitnessFullName", "WitnessContactNumber", "WitnessAgency", "WitnessOther"];
				const agencyFields = ["AgencyContactedFullName", "AgencyContacted", "AgencyContactedContactNumber", "OtherAgencyContacted"];

				if (!otherWitness) {
					const shouldDeleted = formKeys.filter((x) => witnessFields.some((y) => x.includes(y)));
					shouldDeleted.forEach((x) => delete modifiedData[x]);
				}
				if (!otherAgency) {
					const shouldDeleted = formKeys.filter((x) => agencyFields.some((y) => x.includes(y)));
					shouldDeleted.forEach((x) => delete modifiedData[x]);
				} else {
				}
				if (!personKnown) {
					const shouldDeleted = formKeys.filter((x) => x.includes("DetailsOfUser"));
					shouldDeleted.forEach((x) => delete modifiedData[x]);
				} else if (personKnown) {
					const decimalRegex = /(\D+)(\d+)$/;
					formKeys
						.filter((x) => x.includes("DetailsOfUser"))
						.forEach((x) => {
							const match = x.match(decimalRegex);
							if (match) {
								const [combined, title, index] = match;
								if (+index > +personUsing) delete modifiedData[combined];
							}
						});
				}
				const grouped = Object.keys(modifiedData).reduce((acc, curr, index) => {
					if (curr.includes("DetailsOfUserLocation") && !curr.includes("DetailsOfUserLocationSuburbFreeText")) {
						const key2 = curr.match(/^\D*/)[0];
						if (!acc[key2]) acc[key2] = [];
						if (key2 === "DetailsOfUserLocationSuburbRegion") {
							acc[key2].push(modifiedData[curr]?.ItemCodeName || "");
						} else acc[key2].push(modifiedData[curr]);
					}
					return acc;
				}, {});
				let detailsOfUserLocationSuburbRegionObj = {};
				if (Array.isArray(grouped?.DetailsOfUserLocationSuburbRegion))
					detailsOfUserLocationSuburbRegionObj = grouped?.DetailsOfUserLocationSuburbRegion?.reduce((acc, curr, i) => {
						return { ...acc, [`DetailsOfUserLocationSuburbRegion${i + 1}`]: curr };
					}, {});
				// console.log("detailsOfUserLocationSuburbRegionObj", detailsOfUserLocationSuburbRegionObj);
				const merged = [
					...new Set([
						modifiedData?.LocationSuburb || modifiedData?.LocationSuburbRegion?.ItemDisplayName,
						...(grouped.DetailsOfUserLocation || []),
						...(grouped?.DetailsOfUserLocationSuburbRegion || []),
					]),
				];
				setRelatedCCA(merged);
				const formData = {
					...modifiedData,
					ImageUpload: modifiedData?.ImageUpload?.map(({ AttachmentID, FileName }) => ({ AttachmentID, FileName })),
					LocationSuburbRegion: modifiedData?.LocationSuburbRegion?.ItemDisplayName || "",
					DateOfIncident: moment(modifiedData?.DateOfIncident)?.format("DD/MM/YYYY"),

					...detailsOfUserLocationSuburbRegionObj,
				};
				const FormAttachment = modifiedData?.ImageUpload?.map(({ AttachmentID, FileName, AttachmentBase64 }) => ({
					AttachmentID,
					FileName,
					AttachmentBase64,
				}));
				// console.log("formData", formData);
				// return;

				const raw = JSON.stringify({ FormData: formData, FormAttachment });
				const requestOptions = { method: "POST", headers: myHeaders, body: raw, redirect: "follow" };
				const fullName = values.FullName.trim();

				let idbDataKey = params.id || "full_" + fullName + "_" + moment().format("DDMMYYYY");

				if (!navigator.onLine) {
					setLoading(false);
					testIDBKeyval({ ...JSON.parse(JSON.stringify(values)), OfflineSubmit: true }, step);
					setSubmitted(true);
					return;
				}
				fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/awctform/submit`, requestOptions)
					.then((response) => {
						if (response.StatusCode !== 400) return response.json();
						else {
							const errorMessage = { code: response.StatusCod, message: "Error" };
							throw errorMessage;
						}
					})
					.then((result) => {
						setLoading(false);
						try {
							getCCAName(values);
						} catch (error) {
						} finally {
							getCCANameRegion(values);
						}
						setSubmitted(true);
						if (idbDataKey) {
							//should remove the commented out code below
							del(idbDataKey);
						}
					})
					.catch((error) => {
						setLoading(false);
						setVisibleDialog(true);
					});
			}
		}
	}; // eslint-disable-next-line

	const getCCAName = (values) => {
		if (!!values?.LocationSuburb || !!values?.LocationSuburbManual) {
			let val = values?.LocationSuburbManual || values?.LocationSuburb;
			fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/displayname/${val}`)
				.then((response) => {
					if (response.StatusCode !== 400) {
						return response.json();
					} else {
						const errorMessage = { code: response.StatusCod, message: "Error" };
						throw errorMessage;
					}
				})
				.then((result) => {
					setCCAData(result);
					setSubmitted(true);
				})
				.catch((error) => {
					setLoading(false);
					setVisibleDialog(true);
				});
		}
	};

	const getCCANameRegion = (values) => {
		if (!!values?.LocationSuburbRegion) {
			let val = values?.LocationSuburbRegion;
			fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/healthregion/${val.ItemDisplayName}`)
				.then((response) => {
					if (response.StatusCode !== 400) {
						return response.json();
					} else {
						const errorMessage = { code: response.StatusCod, message: "Error" };
						throw errorMessage;
					}
				})
				.then((result) => {
					setCCADataregion(result);
				})
				.catch((error) => {
					setCCADataregion(false);
				});
		}
	};
	const onPrevClick = React.useCallback(
		(event, formRenderProps) => {
			event.preventDefault();

			if (step === 1) {
				const fullName = MyForm?.current?.valueGetter("FullName");
				const formID = params?.id;
				keys().then((dKeys) => {
					const key = dKeys?.find((x) => x.includes(formID || "full_" + fullName + "_"));
					if (!!key && parseInt(step) === 1) {
						setStep(0);
						navigate({ pathname: `/vsu-incident-report?=${new Date()}`, search: createSearchParams({ formID: key }).toString() });
					} else {
						setStep(0);
					}
					return;
				});
				return;
			} else {
				window.scrollTo(0, 0);
				setStep(() => Math.max(step - 1, 0));
			}
		},
		[step, setStep]
	);
	const onUpdateClick = (step) => {
		window.scrollTo(0, 0);
		setShowPreview(false);
		setStep(step);
	};
	const ChildonSubmit = (e, formRenderProps) => {
		setShowPreview(false);
		formRenderProps.onSubmit();
	};

	if (submitted)
		return (
			<>
				<Header />
				<Success formvalue={formValue} CCAData={CCAData} CCADataregion={CCADataregion} relatedCCA={relatedCCA} />
				<Footer />
			</>
		);
	return (
		// eslint-disable-next-line
		<FormContext.Provider
			value={{
				tncCheckBox: [isTnCChecked, setIsTnCChecked],
				personRegionState: [personRegionRequired, setPersonRegionRequired],
				reporterRegionState: [isLocationManualRequired, setIsLocationManualRequired],
			}}
		>
			{visibleDialog && (
				<Dialog title={!isOnline ? "You are currently offline" : "Something went wrong"} onClose={(e) => toggleDialog(false)}>
					<p
						style={{
							margin: "25px",
							textAlign: "center",
						}}
					>
						{!isOnline
							? "Please submit form when mobile data is on"
							: "Something went wrong, Please try again or come back again to submit"}
					</p>
					<DialogActionsBar>
						<button
							className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
							onClick={(e) => setVisibleDialog(false)}
						>
							Ok
						</button>
					</DialogActionsBar>
				</Dialog>
			)}
			<Header />
			<div className="container" style={{ maxWidth: "768px" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Stepper value={step} items={steps} className="my-4" />

					{Loading ? (
						<Loader size="small" type={"infinite-spinner"} />
					) : (
						<Form
							initialValues={formValue}
							// key={JSON.stringify(formValue)}
							onSubmitClick={onStepSubmit}
							ref={MyForm}
							render={(formRenderProps) => (
								<div>
									<FormElement style={{ width: "100%" }}>
										{showPreview ? (
											<Preview
												json={formJson}
												formValue={formValue}
												onUpdateClick={onUpdateClick}
												submitForm={ChildonSubmit}
												formRenderProps={formRenderProps}
											/>
										) : (
											<>
												{stepPages[step]}

												<div className="d-flex justify-content-between k-form-buttons">
													{step !== 0 ? (
														<>
															{step === 1}
															<Button
																size={"large"}
																themeColor={"primary"}
																fillMode={"outline"}
																onClick={onPrevClick}
																className="remove-active"
																selected={false}
																type="button"
															>
																<Icon name="arrow-chevron-left"></Icon>
																Back
															</Button>

															<div>
																{isLastStep && !showPreview && (
																	<Button
																		size={"large"}
																		fillMode={"outline"}
																		className="k-button k-button-lg k-button-outline k-button-outline-primary k-rounded-md mr-1 preview"
																		onClick={formRenderProps.onSubmit}
																	>
																		Preview
																	</Button>
																)}
																<Button
																	size={"large"}
																	themeColor={"primary"}
																	disabled={isLastStep ? !isPreviousStepsValid : false}
																	onClick={formRenderProps.onSubmit}
																	type="submit"
																>
																	{isLastStep ? "Submit" : "Next"}
																	<Icon name="arrow-chevron-right"></Icon>
																</Button>
															</div>
														</>
													) : undefined}
												</div>
											</>
										)}
									</FormElement>
								</div>
							)}
						/>
					)}
				</div>
			</div>
			<Footer />
		</FormContext.Provider>
	);
};

export default VSUIncident;
