import React from "react";
import mainlogo from "../logo.svg";
import { Breadcrumb } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
function Header(props) {
	// Online state
	const [isOnline, setIsOnline] = React.useState(navigator.onLine);
	const navigate = useNavigate();
	React.useEffect(() => {
		// Update network status
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		// Listen to the online status
		window.addEventListener("online", handleStatusChange);

		// Listen to the offline status
		window.addEventListener("offline", handleStatusChange);

		// Specify how to clean up after this effect for performance improvment
		return () => {
			window.removeEventListener("online", handleStatusChange);
			window.removeEventListener("offline", handleStatusChange);
		};
	}, [isOnline]);

	const items = [
		{
			id: "home",
			text: "Home",
		},
		// {
		//   id: "vsu-incident-report",
		//   text: "Incident Report Form",
		// },
	];

	const handleItemSelect = (event) => {
		if (event !== undefined && event.id === "home") {
			navigate(`/`);
		} else {
			navigate(`/${event.id}`);
		}
	};

	return (
		<header className="App-header mb-4">
			{!isOnline && (
				<div className="k-bg-info">
					<div className="container text-center">You're currently offline!</div>
				</div>
			)}
			<div className="container p-4 k-text-primary">
				{props.type === "home" ? (
					<div className="text-center">
						<img src={mainlogo} className="App-logo" alt="logo" />
					</div>
				) : (
					<div>
						<div className="d-flex justify-content-between align-items-center">
							<img src={mainlogo} className="App-logo" alt="logo" />
							<h1 className="text-right">Incident Report Form</h1>
						</div>
					</div>
				)}
			</div>

			{props.type !== "home" && (
				<div className="k-bg-info">
					<div className="container">
						<Breadcrumb data={items} onItemSelect={(e) => handleItemSelect(e)}></Breadcrumb>
					</div>
				</div>
			)}
		</header>
	);
}
export default Header;
