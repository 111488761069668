import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../component/header";
import Footer from "../component/footer";
import { Icon } from "@progress/kendo-react-common";
import mainbanner from "../main-banner-graphic 1.png";
import { keys, get, set, del } from "idb-keyval";
import { Button } from "@progress/kendo-react-buttons";
import { useReactPWAInstall } from "react-pwa-install";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { process } from "@progress/kendo-data-query";
import moment from "moment";
function Home() {
	const [visibleDialog, setVisibleDialog] = React.useState(false);
	const [visibleWindow, setVisibleWindow] = useState(false);
	const [ExistingData, setExistingData] = useState(false);
	const [Key, setKey] = useState(false);
	const navigate = useNavigate();
	const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
	const toggleDialog = async (key) => {
		setKey(key);
		setVisibleDialog(!visibleDialog);
	};
	const toggleWindow = () => {
		setVisibleWindow((vis) => !vis);
	};

	useEffect(() => {
		const updateFormFromDatabase = async () => {
			const formData = await get("FormData");
			const formData2 = await get("FormData2");
			if (formData !== undefined) {
				await checkLastUpdate(formData.lastUpdated, 1);
			} else {
				await fetchData();
			}
			if (formData !== undefined) {
				await checkLastUpdate(formData2, 2);
			} else {
				await fetchData2();
			}
			await fetchData();
			await fetchData2();
		};
		updateFormFromDatabase();
		return () => {};
	}, []);

	useEffect(() => {
		async function submitForm(key, form) {
			const formType = key.split("_")[0].includes("full") ? "form1" : "form2";
			const myHeaders = new Headers({ "Content-Type": "application/json", Cookie: "CMSPreferredUICulture=en-us", formName: formType });
			myHeaders.append("email", form?.FormData?.EmailAddress || "");
			const requestOptions = { method: "POST", headers: myHeaders, body: JSON.stringify(form), redirect: "follow" };
			return fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/awctform/submit`, requestOptions).then((response) => {
				if (response.StatusCode !== 400) return { key, ...response.json() };
				else {
					const errorMessage = { code: response.StatusCod, message: "Error" };
					throw errorMessage;
				}
			});
		}
		async function getAllRows() {
			const keysArray = await keys();
			const rows = [];
			for (const key of keysArray) {
				const value = await get(key);
				rows.push({ key, value });
			}

			const indexOfObject = rows.findIndex((object) => {
				return object.key === "FormData";
			});
			rows.splice(indexOfObject, 1);
			const indexOfObject2 = rows.findIndex((object) => {
				return object.key === "FormData2";
			});
			rows.splice(indexOfObject2, 1);
			if (rows.length > 0) {
				const offlineForms = rows
					.filter((x) => x?.value?.OfflineSubmit)
					.map(({ key, value: y }) => {
						const formData = {
							...y,
							ImageUpload: y?.ImageUpload?.map(({ AttachmentID, FileName }) => ({ AttachmentID, FileName })),
							LocationSuburbRegion: y?.LocationSuburbRegion?.ItemDisplayName || "",
							DateOfIncident: moment(y?.DateOfIncident)?.format("DD/MM/YYYY"),
						};
						const FormAttachment = y?.ImageUpload?.map(({ AttachmentID, FileName, AttachmentBase64 }) => ({
							AttachmentID,
							FileName,
							AttachmentBase64,
						}));
						return { key, form: { FormData: formData, FormAttachment } };
					});
				setExistingData(rows);
				if (rows.length > offlineForms.length) {
					setVisibleWindow(true);
				}
				if (offlineForms?.length > 0) {
					const offlineFormSubmitPromises = offlineForms.map((x) => {
						const { OfflineSubmit, ...formData } = x.form;
						return submitForm(x.key, formData);
					});
					const responses = await Promise.allSettled(offlineFormSubmitPromises);
					const successSubmission = responses.filter((x) => x.status === "fulfilled");
					successSubmission?.forEach((x) => {
						const value = x.value;
						if (value?.key) {
							del(value?.key);
						}
					});
					if (successSubmission?.length > 0) console.log(`Back online. ${successSubmission.length} offline forms have been submitted. `);
				}
			}
		}
		getAllRows();

		return () => {};
	}, [Key]);

	const checkLastUpdate = async (lastUpdated, formType = 1) => {
		if (lastUpdated !== undefined && lastUpdated !== null) {
			if (formType === 1) {
				const date = moment(lastUpdated).format("YYYYMMDD");
				const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/awctform/templatehasupdated/form1/${date}`);
				const json = await response.json();
				if (!json) {
					await fetchData();
				}
			} else {
				const date2 = moment(lastUpdated).format("YYYYMMDD");
				const response2 = await fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/awctform/templatehasupdated/form2/${date2}`);
				const json2 = await response2.json();

				if (!json2) {
					await fetchData2();
				}
			}
		} else {
			formType === 1 ? await fetchData() : await fetchData2();
		}
	};

	// Any other custom service worker logic can go here.
	const fetchData = async () => {
		// Fetch JSON data from API
		const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/awctform/template/form1`);
		const json = await response.json();
		// Store JSON data in IndexedDB
		json.lastUpdated = new Date();
		await set("FormData", json);
	};
	// Any other custom service worker logic can go here.
	const fetchData2 = async () => {
		// Fetch JSON data from API
		const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/awctform/template/form2`);
		const json = await response.json();
		// Store JSON data in IndexedDB
		json.lastUpdated = new Date();
		await set("FormData2", json);
	};
	const deleteSelected = async () => {
		if (Key) {
			del(Key);
			toggleDialog(false);
			const keysA = await keys();
			if (keysA.filter((x) => !["FormData", "FormData2"].includes(x)).length === 0) {
				setVisibleWindow(false);
			}
		}
	};
	return (
		<>
			{visibleDialog && (
				<Dialog title={"Please confirm"} onClose={(e) => toggleDialog(false)}>
					<p
						style={{
							margin: "25px",
							textAlign: "center",
						}}
					>
						Are you sure you want to delete?
					</p>
					<DialogActionsBar>
						<button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={(e) => toggleDialog(false)}>
							No
						</button>
						<button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => deleteSelected()}>
							Yes
						</button>
					</DialogActionsBar>
				</Dialog>
			)}
			{visibleWindow && (
				<Window title={"Pending Submission"} onClose={toggleWindow} width={window.innerWidth > 768 ? 450 : 320}>
					<strong>You have incomplete VSU Incident Report Form/s</strong>
					<div className="mt-3">
						<table>
							<tbody>
								<tr>
									<th colSpan={6}>Name</th>
									<td></td>
									<td></td>
								</tr>

								{Object.keys(ExistingData)
									.filter((key) => !!ExistingData[key]?.value?.FullName && !ExistingData[key]?.value?.OfflineSubmit)

									.map((key) => {
										return (
											<tr className="mb-2" key={key}>
												<td>
													<span className=" mr-2 w-50">{ExistingData[key]?.value?.FullName || ""}</span>
												</td>
												{ExistingData[key].key.split("_")[0] === "breif" ? (
													<td>
														<Button
															size={"small"}
															themeColor={"primary"}
															fillMode={"outline"}
															onClick={() => navigate("/vsu-incident-report-breif/" + ExistingData[key].key)}
														>
															Edit
														</Button>
													</td>
												) : (
													<td>
														<Button
															size={"small"}
															themeColor={"primary"}
															fillMode={"outline"}
															onClick={() => navigate("/vsu-incident-report/" + ExistingData[key].key)}
														>
															Edit
														</Button>
													</td>
												)}
												<td>
													<Button
														size={"small"}
														themeColor={"secondary"}
														fillMode={"outline"}
														onClick={() => toggleDialog(ExistingData[key].key)}
													>
														Delete
													</Button>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				</Window>
			)}
			<Header type="home" />
			<div style={{ maxWidth: "768px", margin: "0 auto" }}>
				<img src={mainbanner} alt="Hero Banner" style={{ width: "100%" }} />
			</div>
			<div className="k-bg-info">
				<div className="container p-4">
					<p className="heading-1">Volatile Substance Use Incident Reporting in WA*</p>
					<div className="d-flex">
						<FontAwesomeIcon icon={faTriangleExclamation} color="#A52700" className="mr-3 mt-1" />
						<span>
							<strong>Please note:</strong> this report will not result in immediate Police assistance. If you require Police
							attendance, dial 131 444 or if it is a life-threatening emergency, dial triple zero (000). Please note, VSU Incident
							Reports are not monitored 24/7 and are received during office hours only by your local Community Alcohol and Drug Service
							(CADS). Please allow up to 72 hours for a response.
						</span>
					</div>
				</div>
			</div>
			<div className="container mt-4">
				<button
					onClick={() => {
						window.location = "/vsu-incident-report";
					}}
					className="k-bg-primary border-0 d-flex justify-content-between align-items-center p-3 mb-2 w-100"
					style={{ cursor: "pointer", textDecoration: "none" }}
				>
					<div className="d-inline-block text-left">
						<span className="text-white">
							<strong>I want to submit a VSU Incident Report</strong>
						</span>
					</div>
					<div className="text-white ml-5">
						<Icon name="arrow-chevron-right"></Icon>
					</div>
				</button>
				<p className="mt-4">
					For more information, visit the{" "}
					<a href="https://vsu.mhc.wa.gov.au/vsu-in-wa/incident-reporting-and-response/" target={"_blank"} rel="noreferrer">
						VSU Incident Reporting and Response system
					</a>
				</p>
				<p className="mt-4">
					*Please note that the VSU Incident Reporting and Response system is currently not available for the Perth metro area. If you wish
					to speak to someone about a VSU issue in the metro area, please contact the{" "}
					<a href="https://vsu.mhc.wa.gov.au/contactfeedback/" target="_blank" rel="noreferrer">
						Volatile Substance Program
					</a>{" "}
					at the{" "}
					<a href="https://www.mhc.wa.gov.au/" target="_blank">
						Mental Health Commission
					</a>{" "}
					or your local{" "}
					<a
						href="https://www.mhc.wa.gov.au/getting-help/community-support-and-treatment-services/community-alcohol-and-drug-services/"
						target="_blank"
						rel="noreferrer"
					>
						Community Alcohol and Drug Service
					</a>{" "}
					or phone the{" "}
					<a target="_blank" href="https://www.mhc.wa.gov.au/about-us/our-services/alcohol-and-drug-support-service/">
						Alcohol and Drug Support Line
					</a>{" "}
					on <a href="tel:0894425000">&#40;08&#41; 9442 5000.</a>
				</p>
			</div>
			<Footer />
		</>
	);
}

export default Home;
