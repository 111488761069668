import { values } from "idb-keyval";

const inputValidator = (value, validationMessage) => {
	if (!value?.trim() && validationMessage?.Required) return validationMessage.Required;
	// if (!value?.trim()) return validationMessage?.Required;
};

const lengthValidator = (value) => {
	if (value?.length < 100) return false;
	return "Maximum 100 characters";
};
const multiSelectValidator = (value, validationMessage) => {
	if (((value !== undefined && value.length < 1) || !value) && validationMessage.Required) {
		return validationMessage.Required;
	}
};
const emailValidator = (value, validationMessage) => {
	if (!value && validationMessage.Required) {
		return validationMessage.Required;
	}

	const emailRegex = new RegExp(/\S+@\S+\.\S+/);
	return emailRegex.test(value) ? "" : validationMessage.Validation;
};

const phoneValidator = (value, validationMessage) => {
	if (!value && validationMessage !== undefined && validationMessage.Required) {
		return validationMessage.Required;
	}

	const phoneRegex = new RegExp(/^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/gm);
	return value.length >= 8 && phoneRegex.test(value) ? "" : validationMessage !== undefined ? validationMessage.Required : "";
};

const textareaValidator = (value, max, validationMessage) => {
	const trimmed = value?.trim() || "";
	if (trimmed?.length === 0) {
		return validationMessage?.Validation || "Insert text here";
	}
	return trimmed && trimmed.length > max ? validationMessage.Validation : "";
};

const fileValidator = (files, maxFiles, validationMessage) => {
	if (!files && validationMessage.Required) {
		return validationMessage.Required;
	}
	return files !== undefined && !!files && files.length > maxFiles ? validationMessage.Validation : false;
};

const extraFileValidator = (files = [], maxFileLength, allowedExtensions) => {
	if (files.length > maxFileLength) return "Max 5 images";

	if (files.some((x) => !allowedExtensions.includes(x.extension))) {
		return "Please remove image file(s) that are not in the allowed formats.";
	}
	return false;
};
const inputDateValidator = (value, validationMessage) => {
	if (!value && validationMessage.Required) {
		return validationMessage.Required;
	}
	const valuedate = new Date(value);
	const Today = new Date();
	let DateError = false;
	let validationErrors = validationMessage.Required;
	if (valuedate >= Today) {
		DateError = true;
		validationErrors = "Future date not valid";
	}
	return value && DateError ? validationErrors : "";
};

const selectValidator = (value, validationMessage, defaultVal, type = "") => {
	let val = false;
	if (type === "LocationSuburbRegion" || type == "DetailsOfUserLocationSuburbRegion") {
		if (value !== undefined && value !== "" && value.ItemCodeName !== defaultVal) {
			return "";
		} else {
			return validationMessage.Required;
		}
	}
	// else if (type==="DetailsOfUserLocationSuburbRegion") {
	// }
	else if (value !== undefined && value !== "" && value !== defaultVal) {
		return "";
	} else {
		return validationMessage.Required;
	}
	// if ( value === undefined || !value && validationMessage.Validation || value === defaultVal) {

	// }
};
export {
	inputValidator,
	lengthValidator,
	inputDateValidator,
	emailValidator,
	phoneValidator,
	textareaValidator,
	fileValidator,
	multiSelectValidator,
	selectValidator,
	extraFileValidator,
};
