import React, { useState, useEffect } from "react";
import { createCustomEqual } from "fast-equals";
import { update, get } from "idb-keyval";
import { useParams } from "react-router-dom";
/**
 * Map
 */

const Map = ({ onIdle, children, style, id, valid, disabled, onChange, formFullName, ...others }) => {
  const params = useParams();
  const updateGMapAddress = (ans) => {
    if (!!ans) {
      const stringified = JSON.stringify(ans);
      update(params?.id || formFullName, (data) => {
        return { ...data, LocationAddress: stringified };
      });
      onChange(stringified);
    }
  };
  const mapRef = React.useRef(null);
  const [map, setMap] = useState();
  const autoCompleteRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [selectedPlace, setSelectedPlace] = useState("");
  const [mapOptions, setmapOptions] = useState(others);
  const [existingAddress, setExistingAddress] = useState("");
  let geocoder = new window.google.maps.Geocoder();
  let [Markers, setMarkers] = useState([]);
  const onClick = (e) => {
    addMarker(e.latLng);
    geocoder.geocode({ latLng: e.latLng }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          var add = results[0].formatted_address;
          setExistingAddress({ name: add, latLng: e.latLng });
          updateGMapAddress({ name: add, latLng: e.latLng });
          inputRef.current.value = add;
        }
      }
    });
  };
  useEffect(() => {
    const getStorageLocation = async () => {
      return !!params?.id ? await get(params?.id) : await get(formFullName);
    };
    getStorageLocation().then((data) => {
      if (!!data?.LocationAddress) {
        try {
          const address = JSON.parse(data?.LocationAddress);
          const gCoord = new window.google.maps.LatLng(address.latLng.lat, address.latLng.lng);
          addMarker(gCoord);
          setExistingAddress({ name: address.name, latLng: address.latLng });
          // onChange(JSON.stringify({ name: address.name, latLng: address.latLng }));
        } catch (error) {}
      }
    });

    return () => {};
  }, [map, mapRef]);

  const addMarker = (position) => {
    MapOnAll(null);
    setMarkers([]);
    const marker = new window.google.maps.Marker({
      position,
    });
    marker.setMap(map);
    // Markers = Markers.push(marker);
    setMarkers([marker]);
  };
  const MapOnAll = (map) => {
    for (let i = 0; i < Markers.length; i++) {
      Markers[i].setMap(map);
    }
  };

  useEffect(() => {
    if (!map) {
      setMap(new window.google.maps.Map(mapRef.current, mapOptions));
      const lat = mapOptions.center.lat;
      const lng = mapOptions.center.lng;
      // const newCoord = new window.google.maps.LatLng(lat, lng);
      // addMarker(newCoord);
    }
  }, [map, mapRef, mapOptions]);

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
      componentRestrictions: { country: "au" },
      fields: ["address_components", "geometry", "name", "formatted_address"],
    });
  }, [selectedPlace, mapOptions]);

  useEffect(() => {
    const placeChangeListener = async () => {
      const place = await autoCompleteRef.current.getPlace();
      // console.log("place", place);
      let loclatlong = place.geometry.location;
      mapOptions.zoom = 16;
      mapOptions.center = loclatlong;
      setTimeout(() => {
        map?.setOptions(mapOptions);
      }, 1);
      setmapOptions((mapOptions) => ({
        ...mapOptions,
        zoom: 16,
        center: loclatlong,
      }));
      setSelectedPlace(place.formatted_address);
      setExistingAddress({ name: place.formatted_address, latLng: loclatlong });
      updateGMapAddress({ name: place.formatted_address, latLng: loclatlong });
      // inputRef.current.value = place.formatted_address;
      addMarker(loclatlong);
    };
    const autoCompleteListener = autoCompleteRef.current.addListener("place_changed", placeChangeListener);

    return () => {
      autoCompleteListener.remove();
    };
  }, [autoCompleteRef.current, mapOptions, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) => window.google.maps.event.clearListeners(map, eventName));
      if (onClick) map.addListener("click", onClick);
      if (onIdle) map.addListener("idle", () => onIdle(map));
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <input
        ref={inputRef}
        aria-describedby={others.ariaDescribedBy}
        aria-invalid={valid}
        placeholder={others.placeholder}
        required={others.required}
        disabled={disabled}
        // name="LocationAddress"
        onChange={(e) => {
          setExistingAddress((data) => ({ ...data, name: e.target.value }));
        }}
        value={existingAddress?.name || ""}
        className={`k-input k-input-md k-rounded-md k-input-solid ${valid ? "" : "k-invalid"}`}
      ></input>
      <div id="map" ref={mapRef} style={style} />
    </>
  );
};

const Places = ({ valid, disabled, ...others }) => {
  return <></>;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (a instanceof window.google.maps.LatLng || b instanceof window.google.maps.LatLng) {
    return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
  }
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export { Map, Places };
