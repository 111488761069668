import React from "react";
import { Preview } from "../controller/preview";
import formJson from "../json/form1.json";

function PreviewPage() {
  return (
    <>
      <Preview json={formJson} />
    </>
  );
}

export default PreviewPage;
