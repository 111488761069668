import React, { useEffect, useState } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { Step1 } from "./steps/step1";
import { step2 } from "./steps/step2";
import { step3 } from "./steps/step3";
import { step4 } from "./steps/step4";
import { step5 } from "./steps/step5";
import { step6 } from "./steps/step6";
import { step7 } from "./steps/step7";
import { Preview } from "../controller/preview";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Icon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { get, set, del, update, keys } from "idb-keyval";
import moment from "moment";
import { useParams, useSearchParams } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { Success } from "../controller/success";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormContext } from "../context/FormContext";
import { useNavigate, createSearchParams } from "react-router-dom";
import "../assets/stylesheets/css/form.css";

const VSUIncident = (props) => {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [isTnCChecked, setIsTnCChecked] = useState(false);
	const [personRegionRequired, setPersonRegionRequired] = useState([]);
	const [isLocationManualRequired, setIsLocationManualRequired] = useState(true);
	const [isOnline, setIsOnline] = React.useState(navigator.onLine);

	const MyForm = React.useRef();
	const [visibleDialog, setVisibleDialog] = React.useState(false);
	const [submitted, setSubmitted] = React.useState(false);
	const [Loading, setLoading] = React.useState(true);
	const [stepPages, setStepPages] = React.useState(0);
	const [formJson, setformJson] = React.useState([]);
	const [step, setStep] = React.useState(1);
	const [showPreview, setShowPreview] = React.useState(false);
	const [formValue, setFormValue] = React.useState({});
	const [CCAData, setCCAData] = React.useState(false);
	const [relatedCCA, setRelatedCCA] = useState([]);
	const [CCADataregion, setCCADataregion] = React.useState(false);
	// const [stepPreviews, setstepPreviews] = React.useState(false);
	const [steps, setSteps] = React.useState([
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
		{ label: "", isValid: undefined },
	]);
	const toggleDialog = (key) => {
		setVisibleDialog(!visibleDialog);
	};

	useEffect(() => {
		setIsOnline(navigator.onLine);
	}, [navigator.onLine]);

	useEffect(() => {
		const fetchData = async () => {
			let retrievedData = await get("FormData2");
			if (retrievedData !== undefined) {
				setformJson(retrievedData.formTemplate);
				let allSteps = [
					Step1(retrievedData.formTemplate),
					step2(retrievedData.formTemplate),
					step3(retrievedData.formTemplate, MyForm),
					step4(retrievedData.formTemplate),
					step5(retrievedData.formTemplate),
					step6(retrievedData.formTemplate),
					step7(retrievedData.formTemplate),
				];

				// let allSteps = [step4(retrievedData.formTemplate),step5(retrievedData.formTemplate), step6(retrievedData.formTemplate), step7(retrievedData.formTemplate)];

				setStepPages(allSteps);
				if (!!params?.id) {
					const getData = await get(params.id);
					setFormValue(getData);
					setIsTnCChecked(getData?.termsandconditions || false);

					setStep(1);
				}
				setLoading(false);
			}
		};

		fetchData();
	}, [params]);

	const testIDBKeyval = async (formData, steps) => {
		if (!!formData) {
			if (!!formData?.FullName) {
				const fullName = formData.FullName.trim();
				let idbDataKey = "breif_" + fullName + "_" + moment().format("DDMMYYYY");
				if (!!params.id) idbDataKey = params.id;

				const { LocationAddress, ...form } = formData;

				update(idbDataKey, (data) => ({ ...data, ...form }));
			}
		}
	};

	const lastStepIndex = steps.length - 1;
	const isLastStep = lastStepIndex === step;
	const isPreviousStepsValid = steps.slice(0, step).findIndex((currentStep) => currentStep.isValid === false) === -1;
	const onStepSubmit = React.useCallback(
		async (event) => {
			const { isValid, values } = event;
			let isPreview = event.event.target.classList.contains("preview") || event.event.target.parentElement.classList.contains("preview");
			setFormValue(values);
			if (isPreview) {
				setTimeout(() => {
					setShowPreview(true);
				}, 100);
			} else {
				window.scrollTo(0, 0);
				const currentSteps = steps.map((currentStep, index) => ({
					...currentStep,
					isValid: index === step ? isValid : currentStep.isValid,
				}));
				setSteps(currentSteps);

				if (!isValid) {
					return;
				}
				testIDBKeyval(JSON.parse(JSON.stringify(values)), step);

				setStep(() => Math.min(step + 1, lastStepIndex));
				if (isLastStep) {
					let emailtoSend = values.EmailAddress;
					if (values.EmailCopyTo !== undefined && values.EmailCopyTo !== null) {
						emailtoSend = values.EmailAddress + ";" + values.EmailCopyTo;
					}
					var myHeaders = new Headers();
					myHeaders.append("formName", "form2");
					myHeaders.append("email", emailtoSend);
					myHeaders.append("Content-Type", "application/json");
					myHeaders.append("Cookie", "CMSPreferredUICulture=en-us");

					const grouped = Object.keys(values).reduce((acc, curr) => {
						if (curr.includes("DetailsOfUserLocation") && !curr.includes("DetailsOfUserLocationSuburbFreeText")) {
							const key2 = curr.match(/^\D*/)[0];
							if (!acc[key2]) acc[key2] = [];
							if (key2 === "DetailsOfUserLocationSuburbRegion") acc[key2].push(values[curr]?.ItemCodeName || "");
							else acc[key2].push(values[curr]);
						}
						return acc;
					}, {});
					const merged = [
						...new Set([
							values?.LocationSuburb || values?.LocationSuburbRegion?.ItemDisplayName,
							...(grouped.DetailsOfUserLocation || []),
							...(grouped?.DetailsOfUserLocationSuburbRegion || []),
						]),
					];
					setRelatedCCA(merged);
					// return;
					const formData = {
						...values,
						ImageUpload: values.ImageUpload?.map(({ AttachmentID, FileName }) => ({ AttachmentID, FileName })) || null,
						LocationSuburbRegion: values?.LocationSuburbRegion?.ItemDisplayName || "",
						DateOfIncident: moment(values?.DateOfIncident)?.format("DD/MM/YYYY"),
					};
					const formDataKeys = Object.keys(formData);
					const briefFormKeys = Object.values((await get("FormData2"))?.formTemplate[0])
						.flat()
						.flatMap((x) => x.Name);
					formDataKeys.forEach((x) => {
						if (!briefFormKeys.includes(x)) {
							delete formData[x];
						}
					});
					const FormAttachment = values?.ImageUpload?.map(({ AttachmentID, FileName, AttachmentBase64 }) => ({
						AttachmentID,
						FileName,
						AttachmentBase64,
					}));

					// console.log("formData", formData);
					// return;

					setLoading(true);

					const raw = JSON.stringify({ FormData: formData, FormAttachment });
					const requestOptions = { method: "POST", headers: myHeaders, body: raw, redirect: "follow" };
					const fullName = values.FullName.trim();
					let idbDataKey = params.id || "breif_" + fullName + "_" + moment().format("DDMMYYYY");
					if (!navigator.onLine) {
						setLoading(false);
						testIDBKeyval({ ...JSON.parse(JSON.stringify(values)), OfflineSubmit: true }, step);
						setSubmitted(true);
						return;
					}
					fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/awctform/submit`, requestOptions)
						.then((response) => {
							if (response.StatusCode !== 400) return response.json();
							else {
								const errorMessage = {
									code: response.StatusCod,
									message: "Error",
								};
								throw errorMessage;
							}
						})
						.then((result) => {
							setLoading(false);
							if (idbDataKey) del(idbDataKey);

							try {
								getCCAName(values);
							} catch (error) {
							} finally {
								getCCANameRegion(values);
							}
							setSubmitted(true);
						})
						.catch((error) => {
							setLoading(false);
							setVisibleDialog(true);
						});
				}
			}
		}, // eslint-disable-next-line
		[steps, isLastStep, step, lastStepIndex, isOnline]
	);

	const getCCAName = (values) => {
		if (!!values?.LocationSuburb || !!values?.LocationSuburbManual) {
			// let val = "kalgoorlie";
			let val = values?.LocationSuburbManual || values?.LocationSuburb;

			fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/displayname/${val}`)
				.then((response) => {
					if (response.StatusCode !== 400) {
						return response.json();
					} else {
						const errorMessage = { code: response.StatusCod, message: "Error" };
						throw errorMessage;
					}
				})
				.then((result) => {
					setCCAData(result);
					setSubmitted(true);
				})
				.catch((error) => {
					setLoading(false);
					setVisibleDialog(true);
				});
		}
	};

	const getCCANameRegion = (values) => {
		if (!!values?.LocationSuburbRegion) {
			let val = values?.LocationSuburbRegion;
			fetch(`${process.env.REACT_APP_API_URL}/v1/aapi/suburb/healthregion/${val.ItemDisplayName}`)
				.then((response) => {
					if (response.StatusCode !== 400) {
						return response.json();
					} else {
						const errorMessage = { code: response.StatusCod, message: "Error" };
						throw errorMessage;
					}
				})
				.then((result) => {
					setCCADataregion(result);
				})
				.catch((error) => {
					setCCADataregion(false);
				});
		}
	};

	const onPrevClick = React.useCallback(
		async (event, formRenderProps) => {
			if (step === 1) {
				const fullName = MyForm?.current?.valueGetter("FullName");
				const formID = params?.id;
				keys().then((dKeys) => {
					const key = dKeys?.find((x) => x.includes(formID || "breif_" + fullName + "_"));
					if (!!key) {
						navigate({ pathname: `/vsu-incident-report`, search: createSearchParams({ formID: key }).toString() });
						setStep(0);
					} else navigate(`/vsu-incident-report`);
					return;
				});
				return;
			} else {
				event.preventDefault();
				window.scrollTo(0, 0);
				setStep(() => Math.max(step - 1, 0));
			}
		},
		[step, setStep]
	);
	const onUpdateClick = (step) => {
		console.log("update clic", formValue);

		window.scrollTo(0, 0);
		setShowPreview(false);
		setStep(step);
	};
	const ChildonSubmit = (e, formRenderProps) => {
		setShowPreview(false);
		formRenderProps.onSubmit();
	};

	if (submitted)
		return (
			<>
				<Header />
				<Success formvalue={formValue} CCAData={CCAData} CCADataregion={CCADataregion} relatedCCA={relatedCCA} />
				<Footer />
			</>
		);
	return (
		// eslint-disable-next-line

		<FormContext.Provider
			value={{
				tncCheckBox: [isTnCChecked, setIsTnCChecked],
				personRegionState: [personRegionRequired, setPersonRegionRequired],
				reporterRegionState: [isLocationManualRequired, setIsLocationManualRequired],
			}}
		>
			{visibleDialog && (
				<Dialog title={!isOnline ? "You are currently offline" : "Something went wrong"} onClose={(e) => toggleDialog(false)}>
					<p
						style={{
							margin: "25px",
							textAlign: "center",
						}}
					>
						{!isOnline
							? "Please submit form when mobile data is on"
							: "Something went wrong, Please try again or come back again to submit"}
					</p>
					<DialogActionsBar>
						<button
							className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
							onClick={(e) => setVisibleDialog(false)}
						>
							Ok
						</button>
					</DialogActionsBar>
				</Dialog>
			)}
			<Header />
			<div className="container" style={{ maxWidth: "768px" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Stepper value={step} items={steps} className="my-4" />

					{Loading ? (
						<Loader size="small" type={"infinite-spinner"} />
					) : (
						<Form
							initialValues={formValue}
							onSubmitClick={onStepSubmit}
							ref={MyForm}
							render={(formRenderProps) => (
								<div>
									<FormElement style={{ width: "100%" }}>
										{showPreview ? (
											<Preview
												json={formJson}
												formValue={formValue}
												onUpdateClick={onUpdateClick}
												submitForm={ChildonSubmit}
												formRenderProps={formRenderProps}
											/>
										) : (
											<>
												{stepPages[step]}

												<div className={"d-flex justify-content-between k-form-buttons"}>
													{step !== 0 ? (
														<>
															<Button
																size={"large"}
																themeColor={"primary"}
																className="remove-active"
																selected={false}
																fillMode={"outline"}
																onClick={onPrevClick}
																type="button"
															>
																<Icon name="arrow-chevron-left"></Icon>
																Back
															</Button>

															<div>
																{isLastStep && !showPreview && (
																	<Button
																		size={"large"}
																		fillMode={"outline"}
																		className="k-button k-button-lg k-button-outline k-button-outline-primary k-rounded-md mr-1 preview"
																		onClick={formRenderProps.onSubmit}
																	>
																		Preview
																	</Button>
																)}
																<Button
																	size={"large"}
																	themeColor={"primary"}
																	disabled={isLastStep ? !isPreviousStepsValid : false}
																	onClick={formRenderProps.onSubmit}
																>
																	{isLastStep ? "Submit" : "Next"}
																	<Icon name="arrow-chevron-right"></Icon>
																</Button>
															</div>
														</>
													) : undefined}
												</div>
											</>
										)}
									</FormElement>
								</div>
							)}
						/>
					)}
				</div>
			</div>
			<Footer />
		</FormContext.Provider>
	);
};

export default VSUIncident;
