import React, { useState, useEffect } from "react";
import { Label } from "@progress/kendo-react-labels";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Icon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { get } from "idb-keyval";

import "../assets/stylesheets/sass/preview.scss";
export const Preview = (data) => {
	const formlabel = data.json[1].StepLabels;
	delete formlabel.Step1;
	const formSteps = Object.keys(formlabel);
	const formFields = data.json[0];
	const formLabels = formlabel;

	const [savedData] = React.useState(data.formValue);
	const [expanded, setExpanded] = React.useState(0);
	const [Loading, setLoading] = React.useState(false);

	const formatValue = (value) => {
		switch (typeof value) {
			case "string":
			default: {
				return value;
			}
			case "array": {
				return value.join(", ");
			}
			case "boolean": {
				return "Yes";
			}
			case "object": {
				if (Array.isArray(value)) {
					return value.join(", ");
				} else {
					if (value.ItemDisplayName !== undefined) return value.ItemDisplayName;
				}
				return "";
			}
		}
	};

	const formatRepeterValue = (field) => {
		// console.log("field", field);
		const { Repeater } = field;
		const name = Repeater[0].Name;
		const keys = Object.keys(savedData);
		const repeaterLength = keys.filter((x) => x.slice(0, -1) === name).length;
		const ParentRepeater = {
			label: field.Label,
			items: [],
		};
		for (let index = 0; index < repeaterLength; index++) {
			const items = [];
			for (const RepeaterItem of Repeater) {
				const item = {};
				const key = RepeaterItem.Name + (index + 1);
				item.label = RepeaterItem.Label;
				item.value = savedData[key];
				item.key = key;
				if (!!savedData[key]) items.push(item);
			}
			ParentRepeater.items.push(items);
		}
		const isRepeaterEmpty = ParentRepeater.items.length <= 0;

		if (field.Name === "DetailsOfUser")
			return (
				<div>
					{/* {!isRepeaterEmpty && <hr />} */}
					{!isRepeaterEmpty &&
						savedData?.PersonsKnown !== "No" &&
						ParentRepeater.items.map((x, index) => {
							return (
								<div key={ParentRepeater.label + index}>
									<label className="k-label k-mt-4">{ParentRepeater.label} </label>
									{" " + (index + 1)}
									{x.map((y) => {
										if (y.key.includes("DetailsOfUserLocationSuburbRegion"))
											return (
												<p key={y.key} style={{ marginBottom: "0.5rem" }}>
													<strong>{y.label}</strong>: {y.value.ItemCodeName}
												</p>
											);
										return (
											<p key={y.key} style={{ marginBottom: "0.5rem" }}>
												<strong>{y.label}</strong>: {y.value}
											</p>
										);
									})}
								</div>
							);
						})}
					{!isRepeaterEmpty && <hr />}
				</div>
			);
		else
			return (
				<div>
					{!isRepeaterEmpty && <hr />}
					{!isRepeaterEmpty &&
						ParentRepeater.items.map((x, index) => {
							return (
								<>
									<label className="k-label k-mt-4">{ParentRepeater.label}</label>
									{index + 1}
									{x.map((y) => {
										if (y.key.includes("DetailsOfUserLocationSuburbRegion"))
											return (
												<p key={y.key} style={{ marginBottom: "0.5rem" }}>
													<strong>{y.label}</strong>: {y.value.ItemCodeName}
												</p>
											);
										return (
											<p key={y.key} style={{ marginBottom: "0.5rem" }}>
												<strong>{y.label}</strong>: {y.value}
											</p>
										);
									})}
								</>
							);
						})}
					{!isRepeaterEmpty && <hr />}
				</div>
			);

		//  }
	};
	const renderField = (field, i) => {
		if (field.Name === "LocationAddress") {
			try {
				const location = JSON.parse(savedData[field.Name]);
				if (!!location)
					return (
						<div key={i + field}>
							<>
								<Label key={i}>
									{field.Label}
									{field.Required ? "*" : " "}
								</Label>
								<p> {location?.name}</p>
							</>
						</div>
					);
				else return <></>;
			} catch (error) {
				return <></>;
			}
		} else
			return (
				<div key={i + field}>
					{field.Name === "LocationAddress" && (
						<>
							<Label key={i}>
								{field.Label}
								{field.Required ? "*" : ""}
							</Label>
							<p> {JSON.parse(savedData[field.Name])?.name}</p>
						</>
					)}
					{field.Name === "NumberOfPersonsUsing" && savedData["PersonsKnown"] !== "No" && (
						<>
							<Label key={i}>
								{field.Label}
								{field.Required ? "*" : " "}
							</Label>
							<p> {JSON.parse(savedData[field.Name])}</p>
							<hr />
						</>
					)}

					{!["CCALABEL", "ImageUpload", "LocationAddress", "NumberOfPersonsUsing"].includes(field.Name) &&
						field.Type !== "repeater" &&
						savedData[field.Name] !== undefined &&
						savedData[field.Name] !== "" && (
							<div key={i}>
								<Label>
									{field.Label}
									{field.Required ? "*" : " "}
								</Label>
								{field.Type === "repeater" && JSON.stringify(field)}
								{field.Type === "date" ? (
									<p> {moment(savedData[field.Name]).format("MMM DD YYYY")}</p>
								) : (
									<p>{formatValue(savedData[field.Name])}</p>
								)}
							</div>
						)}

					{field.Name === "ImageUpload" && savedData[field.Name]?.length > 0 && (
						//key issue
						<div key={i}>
							<Label key={i}>
								{field.Label}
								{field.Required ? "*" : " "}
							</Label>
							<div className="d-flex flex-row">
								{savedData[field.Name]?.map((item, index) => {
									return (
										<div
											key={item.uid}
											className="d-flex flex-column justify-content-between"
											style={{
												// marginBottom: `${index === savedData[field.Name]?.length - 1 ? "0.2rem" : "1rem"}`,
												marginLeft: `${index !== 0 ? "32px" : "0"}`,
											}}
										>
											<img src={item.AttachmentBase64} style={{ width: "150px", height: "100px", objectFit: "contain" }} />
											{item.name}
										</div>
									);
								})}
							</div>
						</div>
					)}
					{field.Type === "repeater" && formatRepeterValue(field)}
				</div>
			);
	};
	const changeInLoading = (e, index) => {
		e.preventDefault();
		setLoading(true);
		setTimeout(() => {
			setExpanded(index + 1);
			setLoading(false);
		}, 1);
	};
	// console.log(formFields["Step3"]);
	return (
		<>
			{Loading ? (
				<></>
			) : (
				<PanelBar className="w-full k-bg-primary align-items-center text-white border-0" keepItemsMounted={true}>
					{/* <pre>{JSON.stringify(data, 0, 2)}</pre> */}
					{formSteps.map((key, index) => {
						// console.log("key", key);
						return (
							<PanelBarItem
								title={formLabels[key]}
								key={key}
								className="text-white preview-panelbaritem"
								expanded={index === expanded}
								selected={index === expanded}
							>
								{formFields[key].map((field, i) => {
									return renderField(field, i);
								})}

								<div className={"d-flex justify-content-between mb-3"}>
									<Button size={"large"} themeColor={"primary"} fillMode={"outline"} onClick={() => data.onUpdateClick(index + 1)}>
										<Icon name="arrow-chevron-left"></Icon>
										Update
									</Button>
									{formSteps.length !== index + 1 ? (
										<Button size={"large"} themeColor={"primary"} onClick={(e) => changeInLoading(e, index)}>
											Next
											<Icon name="arrow-chevron-right"></Icon>
										</Button>
									) : (
										<Button size={"large"} themeColor={"primary"} onClick={(e) => data.submitForm(e, data.formRenderProps)}>
											Submit
											<Icon name="arrow-chevron-right"></Icon>
										</Button>
									)}
								</div>
							</PanelBarItem>
						);
					})}
				</PanelBar>
			)}
		</>
	);
};
