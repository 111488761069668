import * as React from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import {
	Input,
	MaskedTextBox,
	NumericTextBox,
	Checkbox,
	ColorPicker,
	Switch,
	RadioGroup,
	Slider,
	SliderLabel,
	RangeSlider,
	TextArea,
	Rating,
	InputPrefix,
} from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker, DateTimePicker, DateRangePicker, DateInput } from "@progress/kendo-react-dateinputs";
import { Label, Error, FloatingLabel, Hint } from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";
import { update, get } from "idb-keyval";
import moment from "moment";
import { DropDownList, AutoComplete, MultiSelect, ComboBox, MultiColumnComboBox, DropDownTree } from "@progress/kendo-react-dropdowns";
import { Icon } from "@progress/kendo-react-common";
import { processTreeData, expandedState } from "./tree-data-operations";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Map } from "../component/map";
import { filterBy } from "@progress/kendo-data-query";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import PopOverComponent from "./popover";

import "../assets/stylesheets/css/formcomponent.css";
import { useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
const TooltipContentTemplate = (props) => {
	return <div className="tooltipwrapper">{props.title}</div>;
};

const FormInput = (fieldRenderProps) => {
	const collisions = ["fit", "flip", "none"];
	const anchor = React.useRef(null);
	const [show, setShow] = React.useState(false);
	const [collision, setCollision] = React.useState("fit");

	const onClick = () => {
		setShow(!show);
	};
	const { validationMessage, touched, label, id, valid, disabled, hint, type = "text", optional, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	// const errorId = showValidationMessage ? `${id}_error` : "";
	const errorId = showValidationMessage ? `${id}_error` : id;

	const shouldAutocompleteOff = id === "LocationSuburbManual" ? "off" : "on";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<div className={"k-form-field-wrap"}>
				<Input
					{...others}
					valid={valid}
					type={type}
					id={id}
					disabled={disabled}
					// ariaDescribedBy={`${errorId}`}
					autoComplete={shouldAutocompleteOff}
					defaultValue={id === "LocationSuburbManual" && ""}
				/>
				{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
			</div>
		</FieldWrapper>
	);
};

const FormPhone = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, type, optional, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<div className={"k-form-field-wrap"}>
				<Input valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${errorId}`} {...others} />
				{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
			</div>
		</FieldWrapper>
	);
};

const FormRadioGroup = (fieldRenderProps) => {
	const { validationMessage, touched, id, label, valid, disabled, hint, visited, modified, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<RadioGroup ariaDescribedBy={`${errorId}`} ariaLabelledBy={labelId} valid={valid} disabled={disabled} ref={editorRef} {...others} />
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormNumericTextBox = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<NumericTextBox ariaDescribedBy={`${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormCheckbox = (fieldRenderProps) => {
	const { validationMessage, touched, id, valid, disabled, hint, optional, label, visited, modified, name, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const isAnonymousField = name === "DoYouWishToRemainAnonymous";
	return (
		<FieldWrapper>
			<div className="k-display-flex ">
				<Checkbox
					{...others}
					ariaDescribedBy={`${errorId}`}
					label={label}
					labelOptional={optional}
					valid={valid}
					id={id}
					disabled={disabled}
					size={"large"}
					className={`k-darker-checkbox ${isAnonymousField && "bold-label"}`}
				/>
				{hint && name !== "DoYouWishToRemainAnonymous" && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</div>
			{name === "DoYouWishToRemainAnonymous" && (
				<div className="mt-3">
					<small>{hint}</small>
				</div>
			)}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormSwitch = (fieldRenderProps) => {
	const { validationMessage, touched, label, optional, id, valid, disabled, hint, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<Switch ref={editorRef} ariaLabelledBy={labelId} ariaDescribedBy={`${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormMaskedTextBox = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, hint, optional, mask, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} optional={optional}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<div className={"k-form-field-wrap"}>
				<MaskedTextBox ariaDescribedBy={`${errorId}`} valid={valid} id={id} mask={mask} {...others} />
				{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
			</div>
		</FieldWrapper>
	);
};
const FormTextArea = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, hint, disabled, optional, max, ...others } = fieldRenderProps;
	const showValidationMessage = touched && !!validationMessage;

	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={showValidationMessage} optional={optional}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<TextArea
				{...others}
				className={showValidationMessage ? "k-invalid" : ""}
				valid={valid}
				id={id}
				disabled={disabled}
				maxLength={max}
				ariaDescribedBy={`${errorId}`}
				rows={4}
			/>

			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormColorPicker = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<ColorPicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormSlider = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, data, min, max, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<Slider
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				min={min}
				max={max}
				{...others}
			>
				{data.map((value) => (
					<SliderLabel title={value} key={value} position={value}>
						{value.toString()}
					</SliderLabel>
				))}
			</Slider>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormRangeSlider = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, hint, disabled, data, min, max, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<RangeSlider valid={valid} id={id} disabled={disabled} ariaDescribedBy={`${errorId}`} min={min} max={max} {...others}>
				{data.map((value) => {
					return (
						<SliderLabel key={value} position={value}>
							{value.toString()}
						</SliderLabel>
					);
				})}
			</RangeSlider>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormRating = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, hint, disabled, optional, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} optional={optional}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<Rating valid={valid} id={id} disabled={disabled} ariaDescribedBy={`${errorId}`} {...others} />
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormUpload = (fieldRenderProps) => {
	const [filePreviews, setFilePreviews] = React.useState({});
	const [affectedFiles, setAffectedFiles] = React.useState([]);
	React.useEffect(() => {
		affectedFiles
			.filter((file) => !file.validationErrors)
			.forEach((file) => {
				const reader = new FileReader();
				reader.onloadend = (ev) => {
					console.log(ev.target);
					setFilePreviews({
						...filePreviews,
						[file.uid]: ev.target.result,
					});
				};
				if (file && file.getRawFile) {
					reader.readAsDataURL(file.getRawFile());
				}
			});
	}, [affectedFiles, filePreviews]);
	const { id, optional, label, hint, validationMessage, touched, restrictions, value, formData, setFormData, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	const onChangeHandler = async (event) => {
		const toBase64 = (file) =>
			new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = reject;
			});
		const fileInputs = event.newState;
		console.log(event);
		console.log("fileInputs", fileInputs);
		const base64Promises = fileInputs.map(async (x) => {
			if (!!x?.AttachmentBase64) return Promise.resolve(x?.AttachmentBase64);
			return toBase64(x.getRawFile());
		});
		const items = (await Promise.allSettled(base64Promises)).map((x, i) => {
			return {
				...fileInputs[i],
				AttachmentBase64: x.value,
				AttachmentID: fileInputs[i].uid,
				FileName: fileInputs[i].name,
				extension: fileInputs[i].extension,
			};
		});
		console.log("items", items);
		setFormData({ ...formData, [id]: items });
		fieldRenderProps.onChange({ value: items });
	};

	let defaultValue = value !== undefined && value !== null ? value : [];
	if (defaultValue.length == 0) {
		others.valid = true;
	}

	return (
		<FieldWrapper>
			<Label id={labelId} editorId={id} optional={optional}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<Upload
				id={id}
				files={value}
				showActionButtons={false}
				restrictions={restrictions}
				defaultFiles={defaultValue}
				onAdd={onChangeHandler}
				onRemove={onChangeHandler}
				ariaDescribedBy={`${errorId}`}
				ariaLabelledBy={labelId}
				multiple={true}
				withCredentials={false}
				// autoUpload={true}
				autoUpload={false}
				saveUrl={`${process.env.REACT_APP_API_URL}/v1/aapi/forms/upload`}
				removeUrl={`${process.env.REACT_APP_API_URL}/v1/aapi/forms/remove`}
				// success={onUploadSuccess}
				// remove={onUploadRemove}
				// onStatusChange={onStatusChange}
				max={5}
			/>
			<Hint>Max 5MB per image; max 5 images; [.jpg, .jpeg, .png, .heic, .HEIF, .HEVC. allowed]</Hint>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormDropDownList = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<pre style={{ display: "none" }}>{JSON.stringify(fieldRenderProps, 0, 2)}</pre>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>

			<DropDownList
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				optionLabel={others.placeholder}
				{...others}
			/>

			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormAutoComplete = (fieldRenderProps) => {
	const [state, setState] = React.useState({
		data: fieldRenderProps.data,
		value: "",
		opened: false,
	});

	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<AutoComplete
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormComboBox = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	const allData = fieldRenderProps.data;
	const [data, setData] = React.useState(allData.slice());
	const filterData = (filter) => {
		const data = allData.slice();
		return filterBy(data, filter);
	};
	const filterChange = (event) => {
		setData(filterData(event.filter));
	};
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<ComboBox
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				textField={"ItemDisplayName"}
				dataItemKey={"ItemDisplayName"}
				filterable={true}
				onFilterChange={filterChange}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

const FormMultiColumnComboBox = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	const columns = [
		{
			field: "id",
			header: <span>header</span>,
			width: "100px",
		},
		{
			field: "name",
			header: "Name",
			width: "300px",
		},
		{
			field: "position",
			header: "Position",
			width: "300px",
		},
	];
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<MultiColumnComboBox
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				columns={columns}
				textField={"name"}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormMultiSelect = (fieldRenderProps) => {
	const { validationMessage, touched, clearValues, label, id, valid, disabled, hint, wrapperStyle, value, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	const clearAll = () => {
		others.onChange([]);
		clearValues && clearValues();
	};
	const Header = () => {
		return (
			<span onClick={clearAll} className={`multiselect-header ${!!value?.length ? "" : "empty"}`}>
				Select one or more options
			</span>
		);
	};
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<MultiSelect
				{...others}
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				value={value || []}
				defaultItem={others.placeholder}
				header={<Header />}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormDropDownTree = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, data, ...others } = fieldRenderProps;
	const { value, selectField, expandField, dataItemKey, filter } = others;
	const [expanded, setExpanded] = React.useState([data[0][dataItemKey]]);
	const treeData = React.useMemo(
		() =>
			processTreeData(
				data,
				{
					expanded,
					value,
					filter,
				},
				{
					selectField,
					expandField,
					dataItemKey,
					subItemsField: "items",
				}
			),
		[data, expanded, value, filter, selectField, expandField, dataItemKey]
	);
	const onExpandChange = React.useCallback((event) => setExpanded(expandedState(event.item, dataItemKey, expanded)), [expanded, dataItemKey]);
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<DropDownTree
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				data={treeData}
				onExpandChange={onExpandChange}
				dataItemKey={others.dataItemKey}
				textField={others.textField}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormDatePicker = (fieldRenderProps) => {
	const inputRef = React.useRef(null);
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, hintDirection, value, onChange, ...others } =
		fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	React.useEffect(() => {
		if (inputRef.current) {
			inputRef.current.element.type = "date";
		}
	}, []);
	return (
		<FieldWrapper style={wrapperStyle} key={id}>
			<Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<div className={"k-form-field-wrap"}>
				{value !== undefined && value !== null ? (
					<DatePicker
						{...others}
						ref={inputRef}
						dateInput={CustomDateInput}
						adaptive
						mobileMode
						ariaLabelledBy={labelId}
						ariaDescribedBy={`${errorId}`}
						valid={valid}
						id={id}
						disabled={disabled}
						defaultValue={new Date(value)}
						max={new Date()}
						// editable={false}
						DateInputHandle={{ type: "date" }}
						onChange={(e) => {
							// console.log(e);
							onChange(e);
						}}
					/>
				) : (
					<DatePicker
						{...others}
						dateInput={CustomDateInput}
						adaptive
						mobileMode
						ref={inputRef}
						ariaLabelledBy={labelId}
						ariaDescribedBy={`${errorId}`}
						valid={valid}
						id={id}
						disabled={disabled}
						max={new Date()}
						// editable={false}
						onChange={(e) => {
							onChange(e);
						}}
					></DatePicker>
				)}
				{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
			</div>
		</FieldWrapper>
	);
};
export const CustomDateInput = (props) => {
	const [date, setDate] = React.useState(dayjs(props.value).format("YYYY-MM-DD"));
	const inputRef = React.useRef(null);
	React.useEffect(() => {
		setDate(dayjs(props.value).format("YYYY-MM-DD"));
	}, [props.value]);
	const handleChange = (syntheticEvent) => {
		setDate("");
		const dateaa = dayjs(syntheticEvent.$d).format("YYYY-MM-DD");
		if (dateaa.toLocaleLowerCase() !== "invalid date") {
			props.onChange({
				target: this,
				value: new Date(dateaa),
			});
		}
	};
	const handleFocus = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};
	return (
		<React.Fragment>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DateField
					label=""
					onChange={(e) => handleChange(e)}
					onFocus={handleFocus} // Re-focus the input on each render
					value={dayjs(date)}
					format="D/MM/YYYY"
					hiddenLabel={true}
					InputLabelProps={{ disabled: true }}
					className="kendoreact-datepicker"
					id={"date-picker-inline" + props.name}
					InputProps={{ pattern: "[0-9]*" }}
				/>
			</LocalizationProvider>
		</React.Fragment>
	);
};
const FormDateTimePicker = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<DateTimePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				editable={false}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormTimePicker = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<TimePicker ariaLabelledBy={labelId} ariaDescribedBy={`${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormDateInput = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<DateInput ariaLabelledBy={labelId} ariaDescribedBy={`${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormDateRangePicker = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others } = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
				{others.required && "*"}
				{hint && (
					<>
						<PopOverComponent hint={hint} />
					</>
				)}
			</Label>
			<DateRangePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
const FormFloatingNumericTextBox = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, optional, value, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<FloatingLabel optional={optional} editorValue={value} editorId={id} editorValid={valid} editorDisabled={disabled} label={label}>
				<NumericTextBox ariaDescribedBy={`${errorId}`} value={value} valid={valid} id={id} disabled={disabled} {...others} />
			</FloatingLabel>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

const FormMap = (fieldRenderProps) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, type, optional, formFullName, onChange, ...others } = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const errorId = showValidationMessage ? `${id}_error` : "";
	const [clicks, setClicks] = React.useState([]);
	const [center, setCenter] = React.useState({ lat: 0, lng: 0 });
	const params = useParams();
	const [showField, setshowField] = React.useState(navigator.onLine);
	React.useEffect(() => {
		const getStorageLocation = async () => {
			return !!params?.id ? await get(params?.id) : await get(formFullName);
		};
		getStorageLocation().then((data) => {
			if (!!data?.LocationAddress) {
				try {
					const address = JSON.parse(data?.LocationAddress);
					setCenter({ name: address.name, lat: address.latLng.lat, lng: address.latLng.lng });
				} catch (error) {
					setCenter({ lat: -31.9268151, lng: 115.795212 });
				}
			} else setCenter({ lat: -31.9268151, lng: 115.795212 });
		});

		return () => {};
	}, []);
	React.useEffect(() => {
		setshowField(navigator.onLine);
		return () => {};
	}, [navigator.onLine]);

	const render = (status) => {
		switch (status) {
			case Status.LOADING:
				return <></>;
			case Status.FAILURE:
				return <></>;
			case Status.SUCCESS:
				return (
					<Map
						{...others}
						id={id}
						placeholder={others.placeholder}
						formFullName={formFullName}
						center={center}
						onClick={onClick}
						onChange={onChange}
						onIdle={onIdle}
						zoom={12}
						style={{ height: "200px" }}
						disableDefaultUI={true}
						gestureHandling={"cooperative"}
						ariaDescribedby={`${errorId}`}
						valid={valid}
						disabled={disabled}
					></Map>
				);
		}
	};

	const onClick = (e) => {
		console.log("Click at form component", e.latLng);
		// avoid directly mutating state
		setClicks([...clicks, e.latLng || null]);
	};

	const onIdle = (m) => {
		setCenter(m.getCenter().toJSON()); // TODO: this is the value that we're looking for!
	};
	if (showField)
		return (
			<FieldWrapper>
				<Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
					{label}
					{others.required && "*"}
					{hint && (
						<>
							<PopOverComponent hint={hint} />
						</>
					)}
				</Label>
				<div className={"k-form-field-wrap"}>
					{!!center?.lat && (
						<Wrapper
							// apiKey={"AIzaSyCw2FKeiVzKoj_LZayMbKDHSQIJCJFozQ0"}
							apiKey={"AIzaSyBaC0d4ji8vvUBK6q1m-kjOUHlCxVRh8CE"}
							render={render}
							libraries={["places"]}
						/>
					)}
					{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
				</div>
			</FieldWrapper>
		);
	else return <></>;
};

export {
	FormInput,
	FormPhone,
	FormRadioGroup,
	FormNumericTextBox,
	FormCheckbox,
	FormSwitch,
	FormMaskedTextBox,
	FormTextArea,
	FormColorPicker,
	FormSlider,
	FormRangeSlider,
	FormRating,
	FormUpload,
	FormDropDownList,
	FormAutoComplete,
	FormComboBox,
	FormMultiColumnComboBox,
	FormMultiSelect,
	FormDropDownTree,
	FormDatePicker,
	FormDateTimePicker,
	FormTimePicker,
	FormDateInput,
	FormDateRangePicker,
	FormFloatingNumericTextBox,
	FormMap,
};
