import React, { useState, useRef } from "react";
import { Popover } from "@progress/kendo-react-tooltip";
import { Icon } from "@progress/kendo-react-common";

const PopOverComponent = (props) => {
  const anchor = useRef(null);
  const [show, setShow] = useState(false);
  const [collision, setCollision] = useState("fit");
  const { hint } = props;
  const onClick = () => {
    setShow(!show);
  };
  return (
    <>
      <Icon
        name="info"
        themeColor="info"
        // title={hint}
        onMouseEnter={onClick}
        onMouseOut={onClick}
        disabled={show}
        ref={anchor}
      ></Icon>
      <Popover
        show={show}
        anchor={anchor.current && anchor.current.element}
        collision={{
          vertical: "flip",
          horizontal: collision,
        }}
        animate={{ closeDuration: 100, openDuration: 100 }}
        title={hint}
        position="bottom"
        style={{ maxwidth: "235px" }}
      ></Popover>
    </>
  );
};

export default PopOverComponent;
